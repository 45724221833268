<template>
	<resource-search @delete="() => this.$emit('delete')" @input="(id) => this.$emit('input', id)" module="lenders" :can-have-new-item="canHaveNewItem" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :fetch-params="extraFetchParams" v-model="value">
		<template #popover-list-new="{ result }">
			<p class="font-weight-bold mb-0">{{ result.name }}</p>
		</template>

		<template #popover-list="{ result }">
			<p class="font-weight-bold mb-0">{{ result.name }}</p>
			<p class="text-muted mb-0"></p>
		</template>

		<template #selected="{ selected }">
			<b-row>
				<b-col cols="12">
					<p class="font-weight-bold mb-0">{{ selected.name }}</p>
				</b-col>
			</b-row>
		</template>

		<template #selected-form-area>
			<slot name="form-area" />
		</template>

		<template #selected-inline-form-area>
			<slot name="inline-form-area" />
		</template>
	</resource-search>
</template>

<script>
import ResourceSearch from "./ResourceSearch.vue"

export default {
	components: { ResourceSearch },
	props: {
		useDisplayPrice: {
			type: Boolean,
			default: false,
		},
		displayPrice: {
			type: [Number, String],
		},
		displayAdditionalDetails: {
			type: Boolean,
			default: true,
		},
		showDelete: {
			type: Boolean,
			default: false,
		},
		waitForInput: {
			type: Boolean,
			default: true,
		},
		canHaveNewItem: {
			type: Boolean,
			default: false,
		},
		errorFeed: Object,
		errorKey: String,
		type: String,
		value: Object,
	},
	computed: {
		extraFetchParams() {
			if (this.type) {
				return { type: this.type }
			}

			return {}
		},
	},
}
</script>

<style>
</style>
