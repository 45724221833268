<template>
  <b-container>
    <ResourceList
      :can-delete="hasAnyPermission(['delete-orders'])"
      :data="data"
      :is-deleting="isDeleting"
      :is-loading="isLoading"
      :pagination="pagination"
      :search-query="$route.query.query"
      create-to="dash.orders.create"
      resource="order"
      soft-deletes
      title="Orders"
      @paginate="onPaginate"
      @search="onSearch"
      @delete-many="onDeleteMany"
    >
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div v-if="!item.completed" class="d-flex flex-row flex-fill">
            <p class="h5 d-flex flex-column flex-fill mb-1 text-primary">
              <router-link
                :to="{ name: 'dash.orders.view', params: { id: item.id } }"
                class="text-dark">#{{ item.id }} {{ item.customer.name }}
              </router-link>
              <small class="mb-0 text-muted">{{ item.customer.email }}</small>
              <small class="mb-0 text-muted d-flex flex-column">
                <span>{{ item.customer.invoice_address_line_1 }}</span>
                <span>{{ item.customer.invoice_address_line_2 }}</span>
                <span>{{ item.customer.invoice_county }}</span>
                <span>{{ item.customer.invoice_postcode }}</span>
              </small>
            </p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>

          <div v-else class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              <a class="text-dark" @click="openViewModal(item.id)"
              >#{{ item.id }} {{ item.customer.name }}]
              </a>
              <br />
              <small class="mb-0 text-muted">{{ item.customer.email }}</small>
              <small class="mb-0 text-muted d-flex flex-column">
                <span>{{ item.customer.invoice_address_line_1 }}</span>
                <span>{{ item.customer.invoice_address_line_2 }}</span>
                <span>{{ item.customer.invoice_county }}</span>
                <span>{{ item.customer.invoice_postcode }}</span>
              </small>
            </p>
            <p class="align-self-end mb-0 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>

          <div v-if="item.completed" class="mb-1">
            <small class="text-muted mb-2">Completed</small>
          </div>

          <div class="d-none d-md-flex flex-row">
            <b-button
              v-b-popover.hover.bottom="
                'View more information about this order.'
              "
              class="mr-1 mb-0"
              size="sm"
              variant="primary"
              @click="viewOrder(item)"
            >
              <b-icon icon="eye"/>
              View
            </b-button
            >
            <b-button
              v-if="
                item.completed && hasAnyPermission(['update-confirmed-orders'])
              "
              v-b-popover.hover.bottom="'Edit this order.'"
              class="mr-1 mb-0"
              size="sm"
              variant="secondary"
              @click="editOrder(item)"
            >
              <b-icon icon="pen"/>
              Edit
            </b-button
            >
            <b-button
              v-if="!item.completed"
              v-b-popover.hover.bottom="'Edit this order.'"
              class="mr-1 mb-0"
              size="sm"
              variant="secondary"
              @click="editOrder(item)"
            >
              <b-icon icon="pen"/>
              Edit
            </b-button
            >
            <div v-if="!item.completed && item.can_be_marked_as_complete">
              <b-button
                v-b-popover.hover.bottom="'Mark this order as completed.'"
                class="mr-1 mb-0"
                size="sm"
                variant="secondary"
                @click="openCompleteModal(item.id)"
              >
                <b-icon icon="check"/>
                Mark As Complete
              </b-button
              >
            </div>
            <b-dropdown right size="sm" text="Customer Order Invoice">
              <b-dropdown-item :href="`/pdf/orders/customer-invoice/${item.id}`"
              >Download Customer Order Invoice
              </b-dropdown-item
              >
              <b-dropdown-item-button @click="sendCustomerOrderInvoice(item.id)"
              >Send Customer Order Invoice
              </b-dropdown-item-button
              >
            </b-dropdown>
            <b-dropdown class="ml-2" right size="sm" text="Vat Order Invoice">
              <b-dropdown-item :href="`/pdf/orders/vat-invoice/${item.id}`"
              >Download Vat Order Invoice
              </b-dropdown-item
              >
              <b-dropdown-item-button @click="sendVatOrderInvoice(item.id)"
              >Send Vat Order Invoice
              </b-dropdown-item-button
              >
            </b-dropdown>
          </div>
        </div>
      </template>
    </ResourceList>

    <!-- Mark as Complete Modal -->
    <b-modal id="mark-as-complete" hide-footer title="Mark Order As Complete">
      <p>Are you sure you wish to mark this order as complete?</p>
      <div class="d-none d-md-flex flex-row">
        <b-button @click="markComplete()">Complete Order</b-button>
      </div>
    </b-modal>

    <!-- View Modal -->
    <b-modal id="view-modal" hide-footer title="Locked Order">
      <b-form-group label="Password:">
        <b-form-input
          v-model="auth.password"
          placeholder="Please enter the system password to access this order."
          required
          type="password"
        ></b-form-input>
      </b-form-group>
      <div class="d-none d-md-flex flex-row">
        <b-button
          v-b-popover.hover.bottom="'View more information about this order.'"
          class="mr-1 mb-0"
          size="sm"
          variant="primary"
          @click="viewProtectedOrder(viewModalId)"
        >
          <b-icon icon="cart"/>
          View
        </b-button
        >
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal id="edit-modal" hide-footer title="Locked Order">
      <b-form-group label="System Password:">
        <b-form-input
          v-model="auth.password"
          placeholder="Please enter the system password to edit this order."
          required
          type="password"
        ></b-form-input>
      </b-form-group>
      <div class="d-none d-md-flex flex-row">
        <b-button
          v-b-popover.hover.bottom="'Edit this order.'"
          class="mr-1 mb-0"
          size="sm"
          variant="primary"
          @click="editProtectedOrder(editModalId)"
        >
          <b-icon icon="pen"/>
          Edit
        </b-button
        >
      </div>
    </b-modal>
    <!-- End Modal -->
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList";
import currentUser from "../../mixins/currentUser";
import {mapActions, mapGetters} from "vuex";
import FriendlyDate from "../../components/FriendlyDate";

export default {
  mixins: [currentUser],
  created() {
    this.fetchMany(this.$route.query);

    this.$root.$on("bv::modal::hidden", () => {
      this.auth.password = null;
    });
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("orders", ["data", "isDeleting", "isLoading", "pagination"])
  },
  data: () => ({
    auth: {
      password: null
    },

    viewModalId: null,
    editModalId: null,
    completedModalId: null
  }),
  methods: {
    ...mapActions("orders", ["deleteMany", "fetchMany"]),
    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids});
      this.$bvModal.hide(modalId);
      await this.fetchMany(this.$route.query);
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

    submit() {
      let data = {
        password: this.auth.password
      };

      this.save(data);
    },
    viewProtectedOrder(itemId) {
      window.axios
        .post(`orders/authenticate/${itemId}`, {
          password: this.auth.password
        })
        .then(response => {
          if (response.data)
            this.$router.push({
              name: "dash.orders.view",
              params: {id: itemId, pass: this.auth.password}
            });
          this.auth.password = null;
        });
    },
    editProtectedOrder(itemId) {
      window.axios
        .post(`orders/authenticate/${itemId}`, {
          password: this.auth.password
        })
        .then(response => {
          if (response.data)
            this.$router.push({
              name: "dash.orders.update",
              params: {id: itemId, pass: this.auth.password}
            });
          this.auth.password = null;
        });
    },
    markComplete() {
      window.axios
        .get(`/orders/mark-complete/${this.completedModalId}`)
        .then(response => {
          this.$router.go();
        });
    },

    viewOrder(order) {
      if (order.completed) {
        this.openViewModal(order.id);
      } else {
        this.$router.push({
          name: "dash.orders.view",
          params: {id: order.id}
        });
      }
    },

    editOrder(order) {
      if (order.completed) {
        this.openEditModal(order.id);
      } else {
        this.$router.push({
          name: "dash.orders.update",
          params: {id: order.id}
        });
      }
    },

    openCompleteModal(itemId) {
      this.completedModalId = itemId;
      this.$root.$emit("bv::show::modal", "mark-as-complete");
    },

    openViewModal(itemId) {
      this.viewModalId = itemId;
      this.$root.$emit("bv::show::modal", "view-modal");
    },

    openEditModal(itemId) {
      this.editModalId = itemId;
      this.$root.$emit("bv::show::modal", "edit-modal");
    },

    sendCustomerOrderInvoice(orderId) {
      window.axios
        .post(`/orders/customer-invoice/send/${orderId}`)
        .then(response => {
          this.$root.$emit("card-notification", {
            variant: "success",
            title: `Customer Order Invoice Sent.`,
            text: `You have sent the Customer Order Invoice for Order #${orderId}`,
            timeout: 5000
          });
        });
    },

    sendVatOrderInvoice(orderId) {
      window.axios
        .post(`/orders/vat-invoice/send/${orderId}`)
        .then(response => {
          this.$root.$emit("card-notification", {
            variant: "success",
            title: `VAT Order Invoice Sent.`,
            text: `You have sent the VAT Order Invoice for Order #${orderId}`,
            timeout: 5000
          });
        });
    }
  }
};
</script>
