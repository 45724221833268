<template>
	<router-view />
</template>

<script>
import userModule from "../../store/users"
import customerModule from "../../store/customers"
import productModule from "../../store/products"
import noteModule from "../../store/notes"
import paymentModule from "../../store/payments"
import lenderModule from "../../store/lenders"
import supplierModule from "../../store/suppliers"
import supplyOrderItemLotModule from "../../store/supply-order-item-lots"

export default {
	beforeCreate() {
		this.$store.registerModule("users", userModule)
		this.$store.registerModule("customers", customerModule)
		this.$store.registerModule("products", productModule)
		this.$store.registerModule("notes", noteModule)
		this.$store.registerModule("payments", paymentModule)
		this.$store.registerModule("lenders", lenderModule)
		this.$store.registerModule("suppliers", supplierModule)
		this.$store.registerModule("supply-order-item-lots", supplyOrderItemLotModule)
	},
	destroyed() {
		this.$store.unregisterModule("users")
		this.$store.unregisterModule("customers")
		this.$store.unregisterModule("products")
		this.$store.unregisterModule("notes")
		this.$store.unregisterModule("payments")
		this.$store.unregisterModule("lenders")
		this.$store.unregisterModule("suppliers")
		this.$store.unregisterModule("supply-order-item-lots")
	},
}
</script>
