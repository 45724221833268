<template>
  <b-container>
    <b-row class="mb-3">
      <b-col cols="6">
        <h1 class="font-weight-bold text-primary mb-0">View Order</h1>
        <small v-if="order.cancelled_at" class="text-danger">
          Cancelled At: {{ moment(order.cancelled_at).format("lll") }}
        </small>
      </b-col>

      <b-col cols="6">
        <div class="mt-3 mt-md-1 d-md-flex flex-row justify-content-end">
          <b-button v-if="!order.cancelled_at && order.confirmed && hasAnyPermission(['update-confirmed-orders'])"
                    v-b-hover.bottom="'Edit this order.'" class="mr-1 mb-0" variant="primary" @click="editOrder">
            <b-icon icon="pen"/>
            Edit Order
          </b-button>
          <b-button v-if="!order.cancelled_at && !order.confirmed" v-b-hover.bottom="'Edit this order.'"
                    class="mr-1 mb-0" variant="primary"
                    @click="editOrder">
            <b-icon icon="pen"/>
            Edit Order
          </b-button>

          <b-button v-if="!order.cancelled_at" v-b-hover.bottom="'Cancel this order.'" class="mr-1 mb-0"
                    variant="danger"
                    @click="confirmCancel">
            <b-icon icon="x"/>
            Cancel
          </b-button>
          <div class="confirmCancel">
            <b-modal id="confirmCancelModal" :title="'Cancel Order'" hide-footer>
              <p>Are you sure you want to cancel?</p>
              <div class="flex flex-column text-center">
                <button class="btn btn-danger w-25 mr-4" @click="doCancelOrder">Yes</button>
                <button class="btn btn-light w-25" @click="dismissModal">No</button>
              </div>
            </b-modal>
          </div>

        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="
                validationInvalidFeedback(errors.errors, 'user_id')
              " label="Staff Member:">
              <b-form-input v-model="order.user.name" :state="validationState(errors.errors, 'user_id')" disabled/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'customer_id')" label="Customer:">
              <b-input-group>
                <b-form-input v-model="order.customer.name" :state="validationState(errors.errors, 'customer_id')"
                              disabled/>
                <b-input-group-append>
                  <b-button v-b-hover.bottom="'View this customer.'" variant="info" @click="viewOrderCustomer">
                    View Customer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'id')" label="Order ID:">
              <b-form-input v-model="order.id" :state="validationState(errors.errors, 'id')" disabled/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'order_date')"
                          label="Order Date:">
              <b-form-datepicker v-model="order.order_date" :state="validationState(errors.errors, 'order_date')"
                                 disabled></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Order Payments -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="7" role="button" @click.prevent="toggleCollapse('payments')">
                  <b-icon :icon="
                      collapsed['payments'] ? 'chevron-right' : 'chevron-down'
                    " class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Payments</p>
                  </div>
                </b-col>
                <b-col class="d-flex flex-row" cols="5">
                  <div class="d-flex flex-column ml-auto">
                    <b-button v-b-hover.bottom="'Create a payment against this order.'" :to="{
                        name: 'dash.payments.create',
                        query: {
                          order_id: order.id,
                          user_id: order.user_id,
                          total_price: order.total_price}}"
                              class="mr-1 mb-0" size="sm" variant="success">
                      <b-icon class="mr-1" icon="cash"/>
                      Make Payment
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['payments']" class="mt-4">
                <b-col v-if="!order.payments.length" cols="12">
                  <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no payments.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="order.payments.length" cols="12">
                  <b-table-simple class="overflow-auto" hover striped>
                    <b-thead>
                      <b-tr>
                        <b-th>Amount</b-th>
                        <b-th>Method</b-th>
                        <b-th>Payment Made</b-th>
                        <b-th>Actions</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(payment, paymentInd) in order.payments" :key="paymentInd">
                        <b-td>{{ payment.amount | currencyFormat }}</b-td>
                        <b-td>{{ payment.method }}</b-td>
                        <b-td>
                          <FriendlyDate :date="payment.created_at"/>
                        </b-td>
                        <b-td>
                          <b-button v-b-hover.bottom="'View this payment.'" :to="{
                              name: 'dash.payments.view', params: { id: payment.id }}" class="mb-0"
                                    size="sm" variant="secondary">
                            <b-icon icon="cash"/>
                            View
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Refunds -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button" @click.prevent="toggleCollapse('refunds')">
                  <b-icon :icon="
                      collapsed['refunds'] ? 'chevron-right' : 'chevron-down'
                    " class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Refunds</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['refunds']" class="mt-4">
                <b-col v-if="!order.refunds.length" cols="12">
                  <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no refunds.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="order.refunds.length" cols="12">
                  <b-table-simple class="overflow-auto" hover striped>
                    <b-thead>
                      <b-tr>
                        <b-th>Amount</b-th>
                        <b-th>Method</b-th>
                        <b-th>Refund Made</b-th>
                        <b-th>Actions</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(refund, refundInd) in order.refunds" :key="refundInd">
                        <b-td>{{ refund.refund_amount | currencyFormat }}</b-td>
                        <b-td>{{ refund.method }}</b-td>
                        <b-td>
                          <FriendlyDate :date="refund.created_at"/>
                        </b-td>
                        <b-td>
                          <b-button v-b-hover.bottom="'View related payment.'" :to="{
                              name: 'dash.payments.view',
                              params: { id: refund.payment_id }
                            }" class="mb-0"
                                    size="sm" variant="secondary">
                            <b-icon icon="cash"/>
                            View
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Finance Details-->
        <b-row v-if="order.lender_id" class="mb-3">
          <b-col>
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button" @click.prevent="toggleCollapse('finance')">
                  <b-icon :icon="
                      collapsed['finance'] ? 'chevron-right' : 'chevron-down'
                    " class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Finance Details</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['finance']">
                <b-col>
                  <hr/>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="
                      validationInvalidFeedback(errors.errors, 'lender_id')
                    " label="Lender">
                    <b-card>
                      <strong>{{ order.lender.name }}</strong>
                    </b-card>
                  </b-form-group>
                  <b-form-group :invalid-feedback="
                      validationInvalidFeedback(errors.errors, 'finance_amount')
                    " label="Finance Amount">
                    <b-form-input v-model="order.finance_amount"
                                  :state="validationState(errors.errors, 'finance_amount')"
                                  disabled></b-form-input>
                  </b-form-group>
                  <b-form-group :invalid-feedback="
                      validationInvalidFeedback(errors.errors, 'subsidy')
                    " label="Subsidy">
                    <b-form-input v-model="order.subsidy" :state="validationState(errors.errors, 'subsidy')"
                                  disabled></b-form-input>
                  </b-form-group>
                  <b-form-group :invalid-feedback="
                      validationInvalidFeedback(
                        errors.errors,
                        'finance_interest'
                      )
                    " label="Interest %">
                    <b-form-input v-model="order.finance_interest" :state="
                        validationState(errors.errors, 'finance_interest')
                      " disabled></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Uploads -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                       @click.prevent="toggleCollapse('order_uploads')">
                  <b-icon :icon="
                      collapsed['order_uploads']
                        ? 'chevron-right'
                        : 'chevron-down'
                    " class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Uploads</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_uploads']" class="mt-4">
                <b-col v-if="!order.order_uploads.length" cols="12">
                  <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no uploaded files.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="order.order_uploads.length" cols="12">
                  <b-row v-for="order_upload in order.order_uploads" :key="order_upload.id">
                    <b-col cols="12">
                      <b-card class="p-3" no-body>
                        <p>Document Name: {{ order_upload.document.filename }}</p>
                        <p>
                          Upload Date:
                          {{
                            moment(order_upload.created_at).format(
                              "Do MMM YYYY"
                            )
                          }}
                        </p>
                        <div>
                          <b-button v-b-hover.bottom="'View this document.'" class="mr-1 mb-0" size="sm"
                                    variant="secondary">
                            <b-icon icon="eye"/>
                            <a :href="order_upload.document.url" class="text-white" target="_blank">View</a>
                          </b-button>
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Items -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row mb-4" cols="12" md="3" role="button"
                       @click.prevent="toggleCollapse('order_items')">
                  <b-icon :icon="
                      collapsed['order_items'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Items</p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                  <b-button v-b-hover.bottom="'Remaining balance for this order.'" block size="sm" variant="info">
                    Remaining: {{ result | currencyFormat }}
                  </b-button>
                </b-col>
                <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                  <b-button v-b-hover.bottom="'Total payments for this order.'" block size="sm" variant="danger">
                    Payments: {{ computedPaymentTotal | currencyFormat }}
                  </b-button>
                </b-col>
                <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                  <b-button v-b-hover.bottom="'Order total'" block size="sm" variant="success">
                    Total Fitting: {{ computedFittingTotal | currencyFormat }}
                  </b-button>
                </b-col>
                <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                  <b-button v-b-hover.bottom="'Total amount for this order.'" block size="sm" variant="primary">
                    Total Inc Vat: {{ computedOrderTotal | currencyFormat }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_items']" class="mt-4">
                <b-col v-if="order.order_items.length" cols="12">
                  <b-card>
                    <b-row v-if="!collapsed['order_items']" class="mt-4">
                      <b-col v-if="!order.order_items.length" cols="12">
                        <b-col class="text-center" cols="12">
                          <b-card class="py-3 text-center">
                            <p class="mb-4">
                              <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                            </p>
                            <p class="font-weight-normal h5 mb-0 text-dark">There are no orders.</p>
                          </b-card>
                        </b-col>
                      </b-col>
                      <b-col v-if="order.order_items.length" cols="12">
                        <b-table-simple hover responsive striped>
                          <b-thead>
                            <b-tr>
                              <b-th>Product</b-th>
                              <b-th>Quantity</b-th>
                              <b-th>Price</b-th>
                              <b-th>Discount</b-th>
                              <b-th>Shipping Date</b-th>
                              <b-th>Dispatch Date</b-th>
                              <b-th>Fitting Date</b-th>
                              <b-th>Fitting Cost</b-th>
                              <b-th>Status</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(item, itemInd) in order.order_items" v-if="!item.deleted_at" :key="itemInd">
                              <td v-if="item.measurement_types_id === 1" class="d-flex flex-column">
                                <p class="mb-0">{{ item.name }}</p>
                                <small class="text-muted">{{ item.length }} X {{ item.width }}</small>
                                <small v-if="!(item.product && item.product.range && !item.deleted_at)"
                                       class="text-muted">No Range</small>
                                <small v-else class="text-muted">Range: {{ item.product.range }}</small>
                                <small v-if="item.supply_order_item_lot" class="text-muted">
                                  Lot #{{ item.supply_order_item_lot.id }} on
                                  Supply Order Item #{{ item.supply_order_item_lot.supply_order_item_id }}(
                                  <router-link v-b-hover.bottom="'View this supply order'" :to="{
                                      name: 'dash.supply_orders.view',
                                      params: {
                                        id:
                                          item.supply_order_item_lot.supply_order_item.supply_order_id}}"
                                               class="pb-2 pt-2 mb-2">
                                    Supply Order #{{ item.supply_order_item_lot.supply_order_item.supply_order_id }}
                                  </router-link>
                                  )
                                </small>
                                <small v-if="!item.supply_order_item_lot && item.product_variation_id"
                                       class="text-danger">This item has no lot assigned</small>
                              </td>
                              <td v-else>
                                <p class="mb-0">{{ item.name }}</p>
                                <small v-if="!(item.product && item.product.range)" class="text-muted">No Range</small>
                                <small v-else class="text-muted">Range: {{ item.product.range }}</small>
                                <small v-for="supplyOrderItem in item.supply_order_items" :key="
                                    `${item.id}-supply-order-item-${supplyOrderItem.id}`">
                                  {{ supplyOrderItem.pivot.quantity }}
                                  Pack{{ supplyOrderItem.pivot.quantity >= 2 ? "s" : ""
                                  }}
                                  on Supply Order Item #{{ supplyOrderItem.id }}(
                                  <router-link v-b-hover.bottom="'View this supply order'" :to="{
                                      name: 'dash.supply_orders.view',
                                      params: {
                                        id: supplyOrderItem.supply_order_id
                                      }}" class="pb-2 pt-2 mb-2">
                                    Supply Order #{{ supplyOrderItem.supply_order_id }}
                                  </router-link>
                                  )
                                </small>
                                <p><small v-if="item.deleted_at" class="text-muted">Deleted
                                  {{ moment(item.deleted_at).format("Do MMM YYYY") }}
                                </small></p>
                              </td>

                              <td v-if="item.measurement_types_id === 1 && !item.deleted_at">
                                <p class="'mb-0">{{ item.length }} X {{ item.width }}</p>
                              </td>
                              <td v-else-if="item.measurement_types_id === 2">{{ item.quantity }} Packs</td>
                              <td v-else>{{ item.quantity }} Items</td>
                              <td>{{ item.total_price | currencyFormat }}</td>
                              <td>{{ item.discount | currencyFormat }}</td>
                              <td>
                                <div class="d-flex flex-fill flex-column">
                                  <div v-show="!item.deleted_at">
                                    <small v-if="item.ship_date"
                                           class="text-muted">{{ moment(item.ship_date).format("Do MMM YYYY") }}
                                    </small>
                                    <small v-else class="text-muted">Not Shipped</small>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="d-flex flex-fill flex-column">
                                  <div v-show="!item.deleted_at">
                                    <small v-if="item.dispatch_date" class="text-muted">
                                      {{ moment(item.dispatch_date).format("Do MMM YYYY") }}
                                    </small>
                                    <small v-else class="text-muted">No Dispatch</small>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="d-flex flex-fill flex-column">
                                  <div v-show="!item.deleted_at">
                                    <div v-if="!item.fitting_required">
                                      <small class="text-muted">No Fitting Required</small>
                                    </div>
                                    <div v-else>
                                      <small v-if="item.fitting_date" class="text-muted">
                                        {{ moment(item.fitting_date).format("Do MMM YYYY") }}
                                      </small>
                                      <small v-else class="text-muted">No Fitting Date</small>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div v-if="!item.fitting_required">
                                  <small class="text-muted">Fitting Not Required</small>
                                </div>

                                <div v-else>
                                  <div v-if="item.fitting_cost_inc_vat === 0 && item.fitting_cost_exc_vat === 0">
                                    <small class="text-muted">No Fitting Cost</small>
                                  </div>

                                  <div v-else>
                                    <div v-if="item.fitting_cost_inc_vat !== '0.00'">
                                      {{ item.fitting_cost_inc_vat | currencyFormat() }}
                                      <small class="text-muted">Invoice</small>
                                    </div>

                                    <div v-else>
                                      {{ item.fitting_cost_exc_vat | currencyFormat() }}
                                      <small class="text-muted">Direct</small>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div v-if="
                                    item.supply_order_items &&
                                      item.supply_order_items.length
                                  " class="d-flex flex-column">
                                  <router-link v-for="supplyOrderItem in item.supply_order_items" :key="
                                      `supply-order-item-${supplyOrderItem.id}`
                                    "
                                               v-b-popover.bottom="
                                      'View this supply order'
                                    " :class="rowClassStatus(supplyOrderItem)" :to="{
                                      name: 'dash.supply_orders.view',
                                      params: {
                                        id: supplyOrderItem.supply_order_id
                                      }
                                    }" class="pb-2 pt-2 mb-2">
                                    <span class="p-4 text-dark">
                                      {{
                                        supplyOrderItem.status
                                      }}
                                    </span>
                                  </router-link>
                                </div>
                                <div v-if="item.supply_order_item_lot" class="d-flex flex-column">
                                  <router-link v-b-hover.bottom="
                                      'View this supply order'
                                    " :class="
                                      rowClassStatus(
                                        item.supply_order_item_lot
                                          .supply_order_item
                                      )
                                    " :to="{
                                      name: 'dash.supply_orders.view',
                                      params: {
                                        id:
                                          item.supply_order_item_lot
                                            .supply_order_item.supply_order_id
                                      }
                                    }" class="pb-2 pt-2 mb-2">
                                    <span class="p-4 text-dark">
                                      {{
                                        item.supply_order_item_lot
                                          .supply_order_item.status
                                      }}
                                    </span>
                                  </router-link>
                                </div>
                                <div v-if="
                                    !item.supply_order_item_lot &&
                                      (!item.supply_order_items ||
                                        (item.supply_order_items &&
                                          !item.supply_order_items.length))
                                  ">
                                  <span class="pb-2 pt-2 mb-2 goods-in">
                                    <span class="p-4 text-dark">Goods In (Stock)</span>
                                  </span>
                                </div>
                              </td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Notes -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button" @click.prevent="toggleCollapse('order_notes')">
                  <b-icon :icon="
                      collapsed['order_notes']
                        ? 'chevron-right'
                        : 'chevron-down'
                    " class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Order Notes</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_notes']" class="mt-4">
                <b-col cols="12">
                  <b-form-group :invalid-feedback="
                      validationInvalidFeedback(errors.errors, 'newNote')
                    " label="Create a note for this quote">
                    <b-form-textarea v-model="newNote" :state="validationState(errors.errors, 'newNote')"
                                     placeholder="Insert note here..." size="sm"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button variant="primary" @click="addNote">Add Note</b-button>
                </b-col>

                <!-- Add New Note Field -->
                <b-col v-if="order.order_notes.length" cols="12">
                  <hr/>
                  <b-row v-for="(note, noteInd) in order.order_notes" :key="noteInd">
                    <b-col :invalid-feedback="
                        validationInvalidFeedback(
                          errors.errors,
                          'order_notes.' + noteInd + '.note'
                        )
                      " cols="12">
                      <b-card class="p-4 mt-3" no-body>
                        <p class="mb-0">{{ note.note }}</p>
                        <hr/>
                        <b-row>
                          <b-col class="d-flex flex-row justify-content-start align-items-center" cols="9">
                            <p class="mb-0">Left by: {{ note.user.name }}</p>
                          </b-col>
                          <b-col class="d-flex flex-row justify-content-end align-items-center" cols="3">
                            <p class="text-muted mb-0">
                              <FriendlyDate :date="note.created_at"></FriendlyDate>
                            </p>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Activity Log -->
        <b-row v-if="computedAccessLogs" class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button" @click.prevent="toggleCollapse('access_logs')">
                  <b-icon :icon="
                      collapsed['access_logs']
                        ? 'chevron-right'
                        : 'chevron-down'
                    " class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Activity Log</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['access_logs']" class="mt-4">
                <b-col v-if="!order.access_logs.length" cols="12">
                  <b-row>
                    <b-col class="text-center" cols="12">
                      <b-card class="py-3 text-center">
                        <p class="mb-4">
                          <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                        </p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no access logs.</p>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="order.access_logs.length" cols="12">
                  <b-table-simple hover striped>
                    <thead>
                    <tr>
                      <th>Description</th>
                      <th>User</th>
                      <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(log, logInd) in order.access_logs" :key="logInd">
                      <td>{{ log.description }}</td>
                      <td>{{ log.users.name }}</td>
                      <td>
                        {{
                          moment(log.created_at).format(
                            "Do MMM YYYY, h:mm:ss a"
                          )
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal id="edit-modal" hide-footer title="Locked Order">
      <b-form-group label="System Password:">
        <b-form-input v-model="auth.password" placeholder="Please enter the system password to edit this order."
                      required
                      type="password"></b-form-input>
      </b-form-group>
      <div class="d-none d-md-flex flex-row">
        <b-button v-b-hover.bottom="'Edit this order.'" class="mr-1 mb-0" size="sm" variant="primary"
                  @click="editProtectedOrder()">
          <b-icon icon="pen"/>
          Edit
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import {mapActions, mapGetters} from "vuex";
import FriendlyDate from "../../components/FriendlyDate";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [validation, momentMixin],
  components: {
    FriendlyDate,
  },
  created() {
    this.$root.$on("bv::modal::hidden", () => {
      this.auth.password = null;
    });

    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("orders", ["errors", "isLoading", "isSaving", "isDeleting", "single"]),

    computedAccessLogs() {
      if (this.order.hasOwnProperty("access_logs"))
        return this.order.access_logs;
      return false;
    },
    computedOrderTotal() {
      let orderTotal = 0;
      this.order.order_items.forEach((item) => {
        if (!item.deleted_at) {
          if (
            !isNaN(item.sale_price) &&
            !isNaN(item.quantity) &&
            !isNaN(item.fitting_cost_inc_vat)
          ) {
            let itemTotal =
              Number(item.sale_price) * Number(item.quantity) -
              Number(item.discount);
            if (
              item.fitting_required &&
              item.fitting_charge === "Invoice Fitting Charge"
            ) {
              itemTotal += Number(item.fitting_cost_inc_vat);
            }
            orderTotal += Number(itemTotal);
          }
        }
      });
      return orderTotal;
    },
    computedFittingTotal() {
      let fittingTotal = 0;
      this.order.order_items.forEach((item) => {
        if (!Object.keys(item).includes("deleted_at") || !item.deleted_at) {
          if (!isNaN(item.fitting_cost_inc_vat)) {
            fittingTotal += Number(item.fitting_cost_inc_vat);
          }
          if (!isNaN(item.fitting_cost_exc_vat)) {
            fittingTotal += Number(item.fitting_cost_exc_vat);
          }
        }
      });
      return Number(fittingTotal).toFixed(2);
    },
    computedPaymentTotal() {
      let paymentTotal = 0;
      this.order.payments.forEach((payment) => {
        if (!isNaN(payment.amount)) {
          paymentTotal += Number(payment.amount);
        }
      });
      return paymentTotal - this.computedRefundTotal;
    },
    computedRefundTotal() {
      let refundTotal = 0;
      this.order.refunds.forEach((refund) => {
        if (!isNaN(refund.refund_amount)) {
          refundTotal += Number(refund.refund_amount);
        }
      });
      return refundTotal;
    },
    result() {
      let calc = this.computedOrderTotal - this.computedPaymentTotal;
      let calcTwoDP = parseFloat(calc).toFixed(2);
      return calcTwoDP - Number(this.order.subsidy);
    },
  },
  data() {
    return {
      collapsed: {
        order_items: !this.$route.params.id,
        order_notes: true,
        payments: true,
        refunds: true,
        order_uploads: true,
        access_logs: true,
        finance: true,
      },
      newNote: null,
      auth: {password: null},
      editModalId: null,
      order: {
        cancelled_at: null,
        id: null,
        user_id: null,
        user: {},
        customer_id: null,
        customer: {},
        product_id: null,
        products: {},
        payment_id: null,
        payment: {},
        order_items_id: null,
        order_items: [],
        order_date: null,
        quote: null,
        ship_date: null,
        fitting_date: null,
        order_notes: [],
        order_uploads: [],
        uploads: [],
        completed: null,
        refunds: [],
      },
    };
  },
  methods: {
    ...mapActions("orders", ["fetch", "save", "cancelOrder", "deleteMany"]),

    dismissModal() {
      this.$bvModal.hide('confirmCancelModal')
    },

    editOrder() {
      if (this.order.completed) {
        this.openEditModal(this.order.id);
      } else {
        this.$router.push({
          name: "dash.orders.update",
          params: {id: this.order.id},
        });
      }
    },

    viewOrderCustomer() {
      this.$router.push({
        name: "dash.customers.view",
        params: {id: this.order.customer.id},
      });
    },

    openEditModal() {
      this.editModalId = this.order.id;
      this.$root.$emit("bv::show::modal", "edit-modal");
    },

    editProtectedOrder() {
      window.axios
        .post(`orders/authenticate/${this.order.id}`, {
          password: this.auth.password,
        })
        .then((response) => {
          if (response.data)
            this.$router.push({
              name: "dash.orders.update",
              params: {id: this.order.id, pass: this.auth.password},
            });
          this.auth.password = null;
        });
    },

    submit: function () {
      let formData = new FormData();
      formData.append("newNote", this.newNote ?? "");

      this.save({id: this.$route.params.id, data: formData}).then(
        (response) => {
          if ([200, 201].includes(response.status))
            this.$router.push({
              name: "dash.orders.list",
              params: {
                notifications: [
                  {
                    variant: "success",
                    title: `Order #${response.data.id}`,
                    subtitle: `${response.data.customer.name}`,
                    text: `You have ${
                      !this.$route.params.id
                        ? "created a new"
                        : "updated an existing"
                    } order.`,
                    timeout: 5000,
                  },
                ],
              },
            });
        }
      );
    },

    // Calculate Line Total
    calculatedLineTotal(item) {
      let lineTotal = 0;
      let itemTotal =
        Number(
          Number(item.sale_price) * Number(item.quantity) -
          Number(item.discount)
        ) -
        Number(item.fitting_cost_inc_vat) -
        Number(item.fitting_cost_exc_vat);
      lineTotal += Number(itemTotal);
      return lineTotal;
    },

    addNote() {
      window.axios
        .post(`/orders/${this.$route.params.id}/add-note`, {
          note: this.newNote,
        })
        .then((response) => {
          this.order.order_notes.unshift(response.data);
        });
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },

    returnFileUrl() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.order.document && this.order.document.hasOwnProperty("url")) {
        return this.order.document.url;
      }
    },

    doCancelOrder() {
      this.cancelOrder(this.order.id).then((response) => {
        this.fetch(this.order.id);
        this.dismissModal();
      });
    },

    rowClassStatus(supplyOrderItem) {
      if (supplyOrderItem.status === "Ordered") {
        this.class = "ordered";
        return this.class;
      }
      if (supplyOrderItem.status === "Goods In") {
        this.class = "goods-in";
        return this.class;
      }
      if (supplyOrderItem.status === "Partial Goods In") {
        this.class = "part-goods-in";
        return this.class;
      }
      if (supplyOrderItem.status === "Faulted") {
        this.class = "faulted";
        return this.class;
      }
      if (supplyOrderItem.status === "Not Ordered") {
        this.class = "not-ordered";
        return this.class;
      }
    },

    async confirmCancel() {
      this.$bvModal.show('confirmCancelModal');
    },
  },
  watch: {
    single() {
      this.order = this.single;
    },
  },
  filters: {
    currencyFormat: (v) =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(v),
  },
};
</script>

<style lang="scss">
.alert {
  padding: 0;
}

.orders {
  background: #f0cb97;
}

.fitting {
  background: #95ec95;
}

.profit {
  background: rgb(161, 142, 243);
}

.danger {
  color: white;
  background: #d9534f;
}

.warning {
  color: white;
  background: #f0ad4e;
}

.success {
  color: white;
  background: #5cb85c;
}

.ordered {
  color: white;
  background: #ffbf00;
}

.goods-in {
  color: white;
  background: #00ff00;
}

.part-goods-in {
  color: white;
  background: #3792cb;
}

.faulted {
  color: white;
  background: #ff0000;
}

.not-ordered {
  color: black;
  background: white;
}
</style>
