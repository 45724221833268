<template>
	<resource-search @delete="() => this.$emit('delete')" @input="inputEmit" :disabled="disabled" module="supply-order-item-lots" :show-delete="showDelete" :error-feed="errorFeed" :error-key="errorKey" :wait-for-input="waitForInput" :fetch-params="extraFetchParams" v-model="value">
		<template #popover-list="{ result }">
			<b-row>
				<b-col class="d-flex flex-column">
					<h5 class="mb-0">Lot #{{ result.id }}</h5>
					<p class="mb-0 text-muted">Supply Order Item #{{ result.supply_order_item_id }}</p>
					<p class="mb-0 text-muted">Length {{ result['length'] }}</p>
				</b-col>
			</b-row>
		</template>

		<template #selected="{ selected }">
			<b-row>
				<b-col class="d-flex flex-column">
					<h5 class="mb-0">Lot #{{ selected.id }}</h5>
					<p class="mb-0 text-muted">Supply Order Item #{{ selected.supply_order_item_id }}</p>
					<p class="mb-0 text-muted">Length {{ selected['length'] }}</p>
				</b-col>
			</b-row>
		</template>
	</resource-search>
</template>

<script>
import ResourceSearch from "./ResourceSearch.vue"

export default {
	components: { ResourceSearch },
	props: {
		showDelete: {
			type: Boolean,
			default: false
		},
		waitForInput: {
			type: Boolean,
			default: true
		},
		errorFeed: Object,
		errorKey: String,
        disabled: {
            type: Boolean,
            default: false
        },
		productVariation: {
			required: true,
			default: null
		},
		itemId: {
			required: true,
			default: null
		},
		itemLength: {
            required: true,
            default: '0'
        },
		value: Object
	},
	computed: {
		extraFetchParams() {
			var returnable = {};

			if (this.itemLength) {
				returnable['itemLength'] = this.itemLength
			}

			if (this.itemId) {
				returnable['id'] = this.itemId
			}

			if(this.productVariation) {
				returnable['variation'] = this.productVariation
			}

			return returnable
		}
	},
	methods: {
		inputEmit(id) {
			this.$emit('input', id);
		}
	}
}
</script>

<style>
</style>
