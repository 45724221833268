import {
    LOTS_FETCH_MANY_REQUEST,
    LOTS_FETCH_MANY_SUCCESS,
    LOTS_FETCH_MANY_FAILURE,
    LOTS_FETCH_REQUEST,
    LOTS_FETCH_SUCCESS,
    LOTS_FETCH_FAILURE,
    LOTS_SAVE_REQUEST,
    LOTS_SAVE_SUCCESS,
    LOTS_SAVE_FAILURE,
    EMPTY_LOTS,
    CLEAR_ERRORS
} from "./types";

export default {
    fetchMany({ commit }, { query = "", page = 1, extraParams = {} }) {
        commit(LOTS_FETCH_MANY_REQUEST);
        return window.axios
            .get("/supply-orders/lots", { params: { query, page, ...extraParams } })
            .then(response => {
                commit(LOTS_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(LOTS_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetch({ commit }, id) {
        if(id) {
            commit(LOTS_FETCH_REQUEST);
            return window.axios
                .get(`/supply-orders/lots/${id}`)
                .then(response => {
                    commit(LOTS_FETCH_SUCCESS, response);
                    return response;
                })
                .catch(error => {
                    commit(LOTS_FETCH_FAILURE, error);
                    throw error;
                });
        }
    },
    empty({ commit }) {
        commit(EMPTY_LOTS);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    },
};
