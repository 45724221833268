export const LOTS_FETCH_MANY_REQUEST = 'LOTS_FETCH_MANY_REQUEST'
export const LOTS_FETCH_MANY_SUCCESS = 'LOTS_FETCH_MANY_SUCCESS'
export const LOTS_FETCH_MANY_FAILURE = 'LOTS_FETCH_MANY_FAILURE'

export const LOTS_FETCH_REQUEST = 'LOTS_FETCH_REQUEST'
export const LOTS_FETCH_SUCCESS = 'LOTS_FETCH_SUCCESS'
export const LOTS_FETCH_FAILURE = 'LOTS_FETCH_FAILURE'

export const LOTS_SAVE_REQUEST = 'LOTS_SAVE_REQUEST'
export const LOTS_SAVE_SUCCESS = 'LOTS_SAVE_SUCCESS'
export const LOTS_SAVE_FAILURE = 'LOTS_SAVE_FAILURE'

export const EMPTY_LOTS = 'EMPTY_LOTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
