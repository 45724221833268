<template>
  <b-container>
    <ResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :search-query="$route.query.query" can-delete
                  resource="order" soft-deletes title="Empty Prices"
                  @paginate="onPaginate" @search="onSearch" @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <router-link :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="text-dark">
                {{ item.customer.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0">Order ID: {{ item.id }}</p>
          </div>

          <!-- The Modal -->
          <b-modal id="orderAuthView" hide-footer title="Locked Order">
            <b-form-group label="Password:">
              <b-form-input v-model="auth.password" placeholder="Please enter the system password to access this order." required
                            type="password"></b-form-input>
            </b-form-group>
            <div class="d-none d-md-flex flex-row">
              <b-button v-b-popover.hover.bottom="'View more information about this order.'" class="mr-1 mb-0" size="sm"
                        variant="primary"
                        @click="viewProtectedOrder(item)">
                <b-icon icon="cart"/>
                View
              </b-button>
            </div>
          </b-modal>

          <b-modal id="orderAuthEdit" hide-footer title="Locked Order">
            <b-form-group label="System Password:">
              <b-form-input v-model="auth.password" placeholder="Please enter the system password to edit this order." required
                            type="password"></b-form-input>
            </b-form-group>
            <div class="d-none d-md-flex flex-row">
              <b-button v-b-popover.hover.bottom="'Edit this order.'" class="mr-1 mb-0" size="sm" variant="primary"
                        @click="editProtectedOrder(item)">
                <b-icon icon="cart"/>
                View
              </b-button>
            </div>
          </b-modal>
          <!-- End Modal -->

          <div class="d-none d-md-flex flex-row">
            <div v-if="item.completed == 0">
              <b-button v-b-popover.hover.bottom="'View more information about this order.'" :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="mr-1 mb-0"
                        size="sm"
                        variant="primary">
                <b-icon icon="cart"/>
                View
              </b-button>
            </div>
            <div v-else>
              <b-button v-b-modal="'orderAuthView'" v-b-popover.hover.bottom="'View more information about this order.'" class="mr-1 mb-0"
                        size="sm" variant="primary"
                        @click="viewProtectedOrder(item)">
                <b-icon icon="cart"/>
                View
              </b-button>
            </div>

            <div v-if="item.completed == 0">
              <b-button v-b-popover.hover.bottom="'Edit this order.'" :to="{ name: 'dash.orders.update', params: { id: item.id } }" class="mr-1 mb-0" size="sm"
                        variant="secondary">
                <b-icon icon="cart"/>
                Edit
              </b-button>
            </div>
            <div v-else>
              <b-button v-b-modal="'orderAuthEdit'" v-b-popover.hover.bottom="'Edit this order.'" class="mr-1 mb-0" size="sm"
                        variant="secondary" @click="updateProtectedOrder(item)">
                <b-icon icon="cart"/>
                Edit
              </b-button>
            </div>
            <b-button v-b-popover.hover.bottom="'Mark this order as completed.'" class="mr-1 mb-0" size="sm"
                      variant="secondary" @click="markComplete(item)">
              <b-icon icon="check"/>
              Mark As Complete
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchManyEmptyPrices(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate,
  },
  computed: {
    ...mapGetters("orders", ["data", "isDeleting", "isLoading", "pagination"]),
  },
  data() {
    return {
      auth: {
        password: null,
      }
    }
  },
  methods: {
    ...mapActions("orders", ["deleteMany", "fetchManyEmptyPrices"]),
    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchManyEmptyPrices(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: {...this.$route.query, page},
        })
        await this.fetchManyEmptyPrices({...this.$route.query, page})
      }
    },
    async onSearch(query) {
      if (this.$route.query.query !== query) {
        this.$router.replace({
          name: this.$route.name,
          query: {...this.$route.query, query},
        })
        await this.fetchManyEmptyPrices({...this.$route.query, query})
      }
    },
    submit() {
      let data = {
        password: this.auth.password
      }

      this.save(data)
    }
  },
}
</script>
