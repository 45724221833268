var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-search',{attrs:{"module":"lenders","can-have-new-item":_vm.canHaveNewItem,"show-delete":_vm.showDelete,"error-feed":_vm.errorFeed,"error-key":_vm.errorKey,"wait-for-input":_vm.waitForInput,"fetch-params":_vm.extraFetchParams},on:{"delete":function () { return this$1.$emit('delete'); },"input":function (id) { return this$1.$emit('input', id); }},scopedSlots:_vm._u([{key:"popover-list-new",fn:function(ref){
var result = ref.result;
return [_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(result.name))])]}},{key:"popover-list",fn:function(ref){
var result = ref.result;
return [_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(result.name))]),_c('p',{staticClass:"text-muted mb-0"})]}},{key:"selected",fn:function(ref){
var selected = ref.selected;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(selected.name))])])],1)]}},{key:"selected-form-area",fn:function(){return [_vm._t("form-area")]},proxy:true},{key:"selected-inline-form-area",fn:function(){return [_vm._t("inline-form-area")]},proxy:true}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }