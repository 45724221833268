<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col class="d-flex flex-row align-items-center" cols="12">
          <h1 class="font-weight-bold text-primary flex-fill">{{ $route.params.id ? "Update" : "Create" }} Order</h1>
          <b-button v-if="order.quote" disabled variant="outline-danger">Converting Quote</b-button>
        </b-col>

        <b-col cols="12">
          <div v-for="(error, key) in errors.errors" :key="key" class="d-block w-100" style="color: red;">
          <span v-for="(e, ind) in error" :key="ind" class="w-100">
            {{ e }}
          </span>
          </div>
        </b-col>

      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'user_id')"
                            label="Staff Member:">
                <user-search
                  v-model="order.user"
                  :error-feed="errors.errors"
                  :error-key="'user_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="user"
                  @input="userUpdated"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                :invalid-feedback=" validationInvalidFeedback(errors.errors, 'customer_id')" label="Customer:">
                <customer-search
                  v-model="order.customer"
                  :error-feed="errors.errors"
                  :error-key="'customer_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="customer"
                  @input="customerUpdated"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback=" validationInvalidFeedback(errors.errors, 'order_date')"
                            label="Order Date:">
                <b-form-datepicker
                  id="order_date"
                  v-model="order.order_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :state="validationState(errors.errors, 'order_date')"
                  :value="order.order_date"
                  class="mb-2"
                  close-button
                  reset-button/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'quote_source')"
                            label="Order Source:">
                <b-form-select
                  v-model="order.quote_source"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'quote_source')">
                  <b-form-select-option :value="null">Please select an order source</b-form-select-option>
                  <b-form-select-option value="Web Page">Web Page</b-form-select-option>
                  <b-form-select-option value="Facebook">Facebook</b-form-select-option>
                  <b-form-select-option value="Recommendation">Recommendation</b-form-select-option>
                  <b-form-select-option value="Friends & Family">Friends & Family</b-form-select-option>
                  <b-form-select-option value="Repeat Customer">Repeat Customer</b-form-select-option>
                  <b-form-select-option value="Local">Local</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Order Payments -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row v-if="!$route.params.id">
                <b-col cols="12">
                  <b-card>
                    <b-row>
                      <b-col
                        class="d-flex flex-row"
                        cols="12"
                        md="9"
                        role="button"
                        @click.prevent="toggleCollapse('initial_payment')">
                        <b-icon
                          :icon="collapsed['initial_payment'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5">
                        </b-icon>
                        <div class="d-flex flex-column flex-fill ml-4">
                          <p class="h4 font-weight-bold mb-0">Initial Payment</p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="!collapsed['initial_payment']" class="mt-4">
                      <b-col cols="12">
                        <b-form-group
                          :invalid-feedback="validationInvalidFeedback(errors.errors,'initial_payment.location')"
                          label="Location">
                          <b-form-select
                            v-model="initialPayment.location"
                            label="Payment Method">
                            <b-form-select-option :value="null">Please select a payment location</b-form-select-option>
                            <b-form-select-option value="In Store">In Store</b-form-select-option>
                            <b-form-select-option value="Home">Home</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :invalid-feedback="validationInvalidFeedback(errors.errors,'initial_payment.amount')"
                          label="Invoice Amount">
                          <b-form-input v-model="initialPayment.amount"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'initial_payment.amount')"/>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          :invalid-feedback="validationInvalidFeedback(errors.errors,'initial_payment.date')"
                          label="Payment Date">
                          <b-form-datepicker
                            v-model="initialPayment.date"
                            close-button
                            reset-button/>
                        </b-form-group>
                      </b-col>
                      <b-col class="d-flex flex-column justify-content-end" cols="12">
                        <b-row>
                          <b-col cols="12">
                            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'method')"
                                          label="Payment Method">
                              <b-form-select v-model="initialPayment.method" label="Payment Method">
                                <b-form-select-option :value="null">Please select a payment method
                                </b-form-select-option>
                                <b-form-select-option value="Cash">Cash</b-form-select-option>
                                <b-form-select-option value="Debit">Debit</b-form-select-option>
                                <b-form-select-option value="Finance">Finance</b-form-select-option>
                                <b-form-select-option value="BACS">BACS</b-form-select-option>
                                <b-form-select-option value="Cheque">Cheque</b-form-select-option>
                                <b-form-select-option value="Other">Other</b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col v-if="initialPayment.method === 'Other'" cols="12">
                        <b-row>
                          <b-col cols="12">
                            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'other')"
                                          label="Other Payment Method">
                              <b-form-input
                                v-model="initialPayment.other"
                                :disabled="isSaving"
                                :state="validationState(errors.errors, 'other')">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- Finance -->
          <b-row class="mb-3">
            <b-col>
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" role="button" @click.prevent="toggleCollapse('finance')">
                    <b-icon :icon="collapsed['finance'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Finance Details</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['finance']">
                  <b-col>
                    <hr/>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'lender_id')"
                                  label="Lender">
                      <lender-search
                        v-model="order.lender"
                        :error-feed="errors.errors"
                        :error-key="'lender_id'"
                        type="lender"
                        @input="lenderUpdated"/>
                    </b-form-group>
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'finance_amount')"
                                  label="Finance Amount">
                      <b-form-input v-model="order.finance_amount" :disabled="isSaving"
                                    :state="validationState(errors.errors, 'finance_amount')"
                                    @input="calculateSubsidy"></b-form-input>
                    </b-form-group>
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'subsidy_percentage')"
                                  label="Subsidy Percentage">
                      <b-form-input v-model="order.subsidy_percentage" :disabled="isSaving"
                                    :state="validationState(errors.errors, 'subsidy_percentage')"
                                    @input="calculateSubsidy"></b-form-input>
                    </b-form-group>
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'subsidy')"
                                  label="Subsidy">
                      <b-form-input v-model="order.subsidy" :disabled="isSaving"
                                    :state="validationState(errors.errors, 'subsidy')" disabled></b-form-input>
                    </b-form-group>
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'finance_interest')"
                                  label="Interest %">
                      <b-form-input v-model="order.finance_interest" :disabled="isSaving"
                                    :state="validationState(errors.errors, 'finance_interest')"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- Order Uploads -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                         @click.prevent="toggleCollapse('order_uploads')">
                    <b-icon :icon="collapsed['order_uploads'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Uploads</p>
                    </div>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end mb-0" cols="12" md="6">
                    <div class="d-flex flex-column flex-fill ml-4">
                      <b-form-file v-model="order.documents" drop-placeholder="Drop file here..."
                                   placeholder="Choose a file..."></b-form-file>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['order_uploads']" class="mt-4">
                  <b-col v-if="!order.order_uploads.length" cols="12">
                    <b-col class="text-center" cols="12">
                      <b-card class="py-3 text-center">
                        <p class="mb-4">
                          <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                        </p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no uploaded files.</p>
                      </b-card>
                    </b-col>
                  </b-col>
                  <b-col v-if="order.order_uploads.length" cols="12">
                    <b-card>
                      <b-col v-for="order_upload in order.order_uploads" :key="order_upload.id">
                        <b-card class="mb-4">
                          <b-card-text>
                            <b-col class="d-flex justify-content-start" cols="12" md="12">Document
                              Name:{{ order_upload.document.filename }}
                            </b-col>
                          </b-card-text>
                          <b-card-text>
                            <b-col class="d-flex justify-content-start" cols="12" md="12">Upload
                              Date:{{ moment(order_upload.created_at).format("Do MMM YYYY") }}
                            </b-col>
                          </b-card-text>
                          <b-card-text>
                            <b-col class="d-flex justify-content-start" cols="12" md="12">
                              <b-button v-b-popover.bottom="'View this document.'" class="mr-1 mb-0" size="sm"
                                        variant="secondary">
                                <b-icon icon="eye"/>
                                <a :href="order_upload.document.url" class="text-white" target="_blank">View</a>
                              </b-button>
                            </b-col>
                          </b-card-text>
                        </b-card>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- Order Items -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <!-- Top Buttons -->
                <b-row>
                  <b-col class="d-flex flex-row mb-4" cols="12" role="button"
                         @click.prevent="toggleCollapse('order_items')">
                    <b-icon :icon="collapsed['order_items'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Items</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                    <b-button v-b-popover.bottom="'Remaining balance for this order.'" blocksize="sm"
                              variant="info">Remaining: {{ result | currencyFormat }}
                    </b-button>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                    <b-button v-b-popover.bottom="'Total payments for this order'" blocksize="sm"
                              variant="danger">Payments:{{ computedPaymentTotal | currencyFormat }}
                    </b-button>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                    <b-button v-b-popover.bottom="'Order total'" blocksize="sm" variant="success">Total
                      Fitting:{{ computedFittingTotal | currencyFormat }}
                    </b-button>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-center" cols="12" lg="3" md="6">
                    <b-button v-b-popover.bottom="'Order total'" blocksize="sm" variant="primary">Total Inc
                      Vat:{{ computedOrderTotal | currencyFormat }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Order Item Section -->
                <b-row v-if="!collapsed['order_items']" class="mt-4">
                  <b-col v-if="!order.order_items.length" cols="12">
                    <hr class="mt-0"/>
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4">
                            <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                          </p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no order items.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col v-if="order.order_items.length" cols="12">
                    <hr class="mt-0"/>
                    <!-- Short Cut Table -->
                    <b-col cols="12">
                      <b-row>
                        <b-col class="d-flex flex-column mb-4" cols="6">
                          <b-button v-b-popover.bottom="'Mark all shipping dates with current date.'"
                                    blocksize="sm" variant="primary" @click.prevent="markAllShipped()">Mark all as
                            shipped
                          </b-button>
                        </b-col>
                        <b-col class="d-flex flex-column mb-4" cols="6">
                          <b-button blocksize="sm" variant="secondary" v-on:click="showMassAssignFittingModal()">Assign
                            Fitter and Fitting Date
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="d-flex flex-column mb-4">
                          <b-button @click="openDiscountModal">Set Discounts</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="d-flex flex-column mb-4" cols="12">
                          <b-table-simple class="overflow-auto" hover striped>
                            <b-thead>
                              <b-tr>
                                <b-th>Product</b-th>
                                <b-th>Quantity</b-th>
                                <b-th>Price</b-th>
                                <b-th>Total Price</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(item, itemInd) in order.order_items" v-if="!item.deleted_at"
                                    :key="itemInd">
                                <td>
                                  <p class="mb-0">{{ item.name }}</p>
                                  <small v-if="!(item.product && item.product.range)" class="text-muted">No
                                    Range</small>
                                  <small v-else class="text-muted">Range: {{ item.product.range }}</small>
                                  <p><small v-if="item.measurement_types_id === 1" class="text-muted">
                                    {{ item.length }} X {{ item.width }}</small>
                                    <small v-else-if="item.measurement_types_id === 2" class="text-muted">Packs:
                                      {{ item.quantity }}</small>
                                    <small v-else class="text-muted">Items: {{ item.quantity }}</small>
                                  </p>
                                  <p><small v-if="item.deleted_at" class="text-muted">Deleted
                                    {{ moment(item.deleted_at).format("Do MMM YYYY") }}</small></p>
                                </td>

                                <td v-if="item.measurement_types_id === 1">{{ item.quantity }}
                                </td>
                                <td v-else>{{ item.quantity }}</td>

                                <td>{{ item.sale_price | currencyFormat }}</td>
                                <td>{{ item.total_price | currencyFormat }}</td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-col>
                      </b-row>
                    </b-col>
                    <div v-for="(item, ind) in order.order_items" :key="`item-${ind}-${item.id}`"
                         :class="ind%2 === 0 ? '' : 'grey-bg'">
                      <!-- DB Product Search -->
                      <product-search v-if="item.product_id || (item.product_id == null && item.name == null)"
                                      v-show="!item.deleted_at"
                                      v-model="item.product"
                                      :can-have-new-item="!itemAlreadySaved(ind)"
                                      :display-price="item.sale_price"
                                      :error-feed="errors.errors"
                                      :error-key="'order_items.' + ind + '.product_id'"
                                      :order-item-deleted-at="item.deleted_at"
                                      :use-display-price="true"
                                      class="mb-3"
                                      show-delete
                                      type="order_items"
                                      @delete="rmItem(ind)"
                                      @input="itemUpdated(ind)">
                        <template #form-area>
                          <!-- Square Meters -->
                          <b-row v-if="item.measurement_types_id === 1">
                            <!-- Row 1 -->
                            <b-col v-if="item.product.variations.length" cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,`order_items.${ind}.product_variation_id`)"
                                class="mt-4"
                                prepend="Variation">
                                <b-form-select v-model="item.product_variation_id" :disabled="isSaving"
                                               :state="validationState(errors.errors,`order_items.${ind}.product_variation_id`)"
                                               @change="productVariationUpdated(ind)">
                                  <b-form-select-option :value="null">Please select a variation</b-form-select-option>
                                  <b-form-select-option v-for="variation in item.product.variations"
                                                        :key="`variation${ind}-${variation.id}`" :value="variation.id">
                                    {{ variation.name }} - Width: {{ variation.width }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-input-group>
                              <small
                                class="text-danger">{{ validationInvalidFeedback(errors.errors, `order_items.${ind}.product_variation_id`)
                                }}</small>
                            </b-col>
                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.length')"
                                class="mt-4"
                                prepend="Length">
                                <template #append>
                                  <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                </template>
                                <b-form-input
                                  v-model="item.length"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' + ind + '.length')"
                                  @change="item.supply_order_item_lot = {}"
                                  @input="calculateSquareMeters(ind)">
                                </b-form-input>
                              </b-input-group>
                              <small
                                class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".length")
                                }}</small>
                            </b-col>
                            <b-col cols="12">
                              <b-card class="mt-4">
                                <h4>Lot</h4>
                                <SupplyOrderItemLotSearch
                                  v-show="!item.deleted_at"
                                  v-model="item.supply_order_item_lot"
                                  :disabled="lotShouldBeDisabled(ind)"
                                  :error-feed="errors.errors"
                                  :error-key="'order_items.' +ind +'.supply_order_item_lot_id'"
                                  :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_item_lot')"
                                  :item-id="item.id"
                                  :item-length="item['length']"
                                  :product-variation="item.product_variation_id"
                                  :wait-for-input="false"
                                  show-delete
                                  @delete="rmLot(ind)"/>
                              </b-card>
                              <small
                                class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".length")
                                }}</small>
                            </b-col>
                            <!-- End Row 1 -->

                            <!-- Row 2 -->
                            <b-col cols="12">
                              <b-row>
                                <b-col cols="6">
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                                    class="mt-4"
                                    prepend="Price £">
                                    <b-form-input v-model="item.sale_price" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                                  placeholder="Price Inc VAT"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small
                                    class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price")
                                    }}</small>
                                </b-col>
                                <b-col cols="6">
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                                    class="mt-4"
                                    prepend="Discount £">
                                    <b-form-input v-model="item.discount" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' + ind + '.discount')"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                </b-col>
                              </b-row>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.ship_date')"
                                class="mt-4"
                                prepend="Shipping Date">
                                <b-form-datepicker v-model="item.ship_date"
                                                   :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                   :disabled="isSaving"
                                                   :state=
                                                     "validationState(errors.errors,'order_items.' + ind + '.ship_date')"
                                                   close-button
                                                   reset-button
                                                   size="sm"
                                                   @input="updateOrderItemTotal(ind)"/>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.dispatch_date')"
                                class="mt-4"
                                prepend="Dispatch Date">
                                <b-form-datepicker
                                  v-model="item.dispatch_date"
                                  :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' + ind + '.dispatch_date')"
                                  close-button
                                  reset-button
                                  size="sm"
                                  @input="updateOrderItemTotal(ind)"/>
                              </b-input-group>
                            </b-col>

                            <b-col class="mt-4" cols="12">
                              <b-card>
                                <div class="d-flex flex-row">
                                  <div class="flex-fill">
                                    <h4 class="mb-0">Fitting Details</h4>
                                  </div>
                                  <div>
                                    <b-form-checkbox v-model="item.fitting_required" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' +ind +'.fitting_required')"
                                                     :unchecked-value="false" :value="true">Fitting Required
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <hr v-if="item.fitting_required === true"/>
                                <b-row v-if="item.fitting_required === true">
                                  <b-col cols="12">
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                      class="mt-4"
                                      prepend="Fitting Date">
                                      <b-form-datepicker v-model="item.fitting_date"
                                                         :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                         :disabled="isSaving"
                                                         :state="validationState(errors.errors,'order_items.' +ind +'.fitting_date')"
                                                         close-button
                                                         reset-button
                                                         size="sm"/>
                                    </b-input-group>
                                    <small
                                      class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                      }}
                                    </small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_time')"
                                      class="mt-4"
                                      prepend="Fitting Time">
                                      <b-form-input v-model="item.fitting_time" :disabled="isSaving"
                                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_time')"
                                                    placeholder="HH:MM"/>
                                    </b-input-group>
                                    <small
                                      class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                      }}</small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_charge')"
                                      class="mt-4"
                                      prepend="Fitting Charge">
                                      <b-form-select v-model="item.fitting_charge" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' +ind +'.fitting_charge')">
                                        <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                        <b-form-select-option value="Invoice Fitting Charge">Invoice
                                        </b-form-select-option>
                                        <b-form-select-option value="Direct Fitting Charge">Direct
                                        </b-form-select-option>
                                      </b-form-select>
                                    </b-input-group>
                                    <small
                                      class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                      }}</small>
                                    <b-input-group v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                                   :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                   class="mt-4"
                                                   prepend="Fitting Cost £">
                                      <b-form-input v-model="item.fitting_cost_inc_vat" :disabled="isSaving"
                                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                    placeholder="Fitting Cost Inc Vat"
                                                    @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                           class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                      }}</small>
                                    <b-input-group v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                                   :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                   class="mt-4"
                                                   prepend="Fitting Cost £">
                                      <b-form-input v-model="item.fitting_cost_exc_vat" :disabled="isSaving"
                                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                    placeholder="Fitting Cost Exc Vat"
                                                    @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                           class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                      }}</small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitter_color')"
                                      class="mt-4" prepend="Fitter">
                                      <b-form-select v-model="item.fitter_color" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' +ind +'.fitter_color')">
                                        <b-form-select-option :value="null" default>No Fitter Selected
                                        </b-form-select-option>
                                        <b-form-select-option value="#4cd137">Darren</b-form-select-option>
                                        <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
                                        <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
                                        <b-form-select-option value="#d63031">Mark</b-form-select-option>
                                        <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
                                        <b-form-select-option value="#e67e22">Craig</b-form-select-option>
                                        <b-form-select-option value="#e84393">Danny</b-form-select-option>
                                        <b-form-select-option value="##b2bec3">Dave</b-form-select-option>
                                        <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
                                        <b-form-select-option value="#636e72">Carl</b-form-select-option>
                                      </b-form-select>
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitter_color")
                                      }}
                                    </small>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-col>

                            <!-- End Row 2 -->

                            <b-col cols="12">
                              <b-form-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                                class="mt-4"
                                label="Supplier">
                                <supplier-search
                                  v-model="item.supplier"
                                  :error-feed="errors.errors"
                                  :error-key="'supplier_id'"
                                  type="supplier"
                                  @input="supplierUpdated(ind)"/>
                              </b-form-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                              </small>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                                class="mt-4 w-100">
                                <b-form-checkbox v-model="item.staircase" :disabled="isSaving"
                                                 :state="validationState(errors.errors,'order_items.' + ind + '.staircase')"
                                                 :value="true">
                                  Staircase Involved
                                </b-form-checkbox>
                              </b-input-group>
                            </b-col>
                          </b-row>
                          <!-- End Square Meters -->

                          <!-- Packs -->
                          <b-row v-else>
                            <b-col v-if="item.product.variations.length" cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'product_variation_id')"
                                class="mt-4"
                                prepend="Variation">
                                <b-form-select v-model="item.product_variation_id" :disabled="isSaving"
                                               :state="validationState(errors.errors,'order_items.' +ind +'.product_variation_id')"
                                               @change="productVariationUpdated(ind)">
                                  <b-form-select-option :value="null">Please select a variation</b-form-select-option>
                                  <b-form-select-option v-for="variation in item.product.variations"
                                                        :key="`variation${ind}-${variation.id}`" :value="variation.id">
                                    {{ variation.name }} - Width: {{ variation.width }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.quantity')"
                                class="mt-4" prepend="Quantity">
                                <b-form-input v-model="item.quantity" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.quantity')"
                                              @input="updateOrderItemTotal(ind)">
                                </b-form-input>
                              </b-input-group>
                              <small
                                class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".quantity")
                                }}</small>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                                class="mt-4" prepend="Price £">
                                <b-form-input v-model="item.sale_price" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                              placeholder="Price Inc VAT" @input="updateOrderItemTotal(ind)"/>
                              </b-input-group>
                              <small
                                class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price")
                                }}</small>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                                class="mt-4" prepend="Discount £">
                                <b-form-input v-model="item.discount" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.discount')"
                                              @input="updateOrderItemTotal(ind)"/>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.ship_date')"
                                class="mt-4" prepend="Shipping Date">
                                <b-form-datepicker v-model="item.ship_date"
                                                   :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                   :disabled="isSaving"
                                                   :state="validationState(errors.errors,'order_items.' + ind + '.ship_date')"
                                                   close-button
                                                   reset-button
                                                   size="sm"
                                                   @input="updateOrderItemTotal(ind)"/>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.dispatch_date')"
                                class="mt-4" prepend="Dispatch Date">
                                <b-form-datepicker v-model="item.dispatch_date"
                                                   :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                   :disabled="isSaving"
                                                   :state="validationState(errors.errors,'order_items.' + ind + '.dispatch_date')"
                                                   close-button
                                                   reset-button
                                                   size="sm"
                                                   @input="updateOrderItemTotal(ind)"/>
                              </b-input-group>
                            </b-col>

                            <b-col class="mt-4" cols="12">
                              <b-card>
                                <div class="d-flex flex-row">
                                  <div class="flex-fill">
                                    <h4 class="mb-0">Fitting Details</h4>
                                  </div>
                                  <div>
                                    <b-form-checkbox v-model="item.fitting_required" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' +ind +'.fitting_required')"
                                                     :unchecked-value="false" :value="true">Fitting Required
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <hr v-if="item.fitting_required === true"/>
                                <b-row v-if="item.fitting_required === true">
                                  <b-col cols="12">
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                      class="mt-4" prepend="Fitting Date">
                                      <b-form-datepicker v-model="item.fitting_date"
                                                         :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                         :disabled="isSaving"
                                                         :state="validationState(errors.errors,'order_items.' +ind +'.fitting_date')"
                                                         close-button close-buttonreset-buttonsize="sm"
                                                         reset-button/>
                                    </b-input-group>
                                    <small
                                      class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                      }}</small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_time')"
                                      class="mt-4" prepend="Fitting Time">
                                      <b-form-input v-model="item.fitting_time" :disabled="isSaving"
                                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_time')"
                                                    placeholder="HH:MM"/>
                                    </b-input-group>
                                    <small
                                      class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                      }}</small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_charge')"
                                      class="mt-4" prepend="Fitting Charge">
                                      <b-form-select v-model="item.fitting_charge" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' +ind +'.fitting_charge')">
                                        <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                        <b-form-select-option value="Invoice Fitting Charge">Invoice
                                        </b-form-select-option>
                                        <b-form-select-option value="Direct Fitting Charge">Direct
                                        </b-form-select-option>
                                      </b-form-select>
                                    </b-input-group>
                                    <small
                                      class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                      }}</small>
                                    <b-input-group v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                                   :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                   class="mt-4" prepend="Fitting Cost £">
                                      <b-form-input v-model="item.fitting_cost_inc_vat" :disabled="isSaving"
                                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                    placeholder="Fitting Cost Inc Vat"
                                                    @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                           class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                      }}</small>
                                    <b-input-group v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                                   :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                   class="mt-4" prepend="Fitting Cost £">
                                      <b-form-input v-model="item.fitting_cost_exc_vat" :disabled="isSaving"
                                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                    placeholder="Fitting Cost Exc Vat"
                                                    @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                           class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                      }}</small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitter_color')"
                                      class="mt-4" prepend="Fitter">
                                      <b-form-select v-model="item.fitter_color" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' +ind +'.fitter_color')">
                                        <b-form-select-option :value="null" default>Please select a fitter
                                        </b-form-select-option>
                                        <b-form-select-option value="#4cd137">Darren</b-form-select-option>
                                        <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
                                        <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
                                        <b-form-select-option value="#d63031">Mark</b-form-select-option>
                                        <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
                                        <b-form-select-option value="#e67e22">Craig</b-form-select-option>
                                        <b-form-select-option value="#e84393">Danny</b-form-select-option>
                                        <b-form-select-option value="##b2bec3">Dave</b-form-select-option>
                                        <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
                                        <b-form-select-option value="#636e72">Carl</b-form-select-option>
                                      </b-form-select>
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitter_color")
                                      }}
                                    </small>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-col>

                            <b-col cols="12">
                              <b-form-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                                class="mt-4" label="Supplier">
                                <supplier-search
                                  v-model="item.supplier"
                                  :error-feed="errors.errors"
                                  :error-key="'supplier_id'"
                                  type="supplier"
                                  @input="supplierUpdated(ind)"/>
                              </b-form-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                              </small>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                                class="mt-4 w-100">
                                <b-form-checkbox v-model="item.staircase" :disabled="isSaving"
                                                 :state="validationState(errors.errors,'order_items.' + ind + '.staircase')"
                                                 :value="true">Staircase Involved
                                </b-form-checkbox>
                              </b-input-group>
                            </b-col>
                          </b-row>
                          <!-- End Packs -->

                          <!-- Split Shipping Modal -->
                          <b-row>
                            <b-col cols="12" md="6">
                              <b-input-group class="mt-4">
                                <b-button variant="primary" v-on:click="showSplitShippingModal(ind)">
                                  <fa-icon icon="columns"/>
                                  Split Shipping
                                </b-button>
                              </b-input-group>
                            </b-col>
                          </b-row>
                        </template>
                      </product-search>

                      <!-- None DB Product -->
                      <b-card v-if="item.product_id == null && item.name" v-show="!item.deleted_at" class="mb-3">
                        <b-row class="mb-3">
                          <b-col>
                            test
                            <div>
                              <p class="font-weight-bold mb-0">{{ item.name }}
                                <b-badge variant="primary">{{ item.sale_price | currencyFormat() }}</b-badge>
                              </p>
                              <small v-if="itemAlreadySaved(ind)" class="text-danger">
                                Non Database products cannot be updated, if you
                                wish to update this, please delete the item and create another row.
                              </small>
                            </div>
                            <p v-if="item.deleted_at" class="mb-0"><small class="text-danger">Order Item Deleted</small>
                            </p>
                          </b-col>
                          <b-col class="d-flex align-items-center justify-content-end" cols="5">
                            <b-button class="ml-2" variant="danger" @click="rmItem(ind)">
                              <fa-icon icon="trash"/>
                            </b-button>
                          </b-col>
                        </b-row>
                        <!-- Measurement Type Selector -->
                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.measurement_types_id')"
                              label="Measurement Type">
                              <b-form-select v-model="item.measurement_types_id"
                                             :disabled="isSaving || itemAlreadySaved(ind)"
                                             :state="validationState(errors.errors,'order_items.' +ind +'.measurement_types_id')">
                                <b-form-select-option :value="null">Please select a measurement type
                                </b-form-select-option>
                                <b-form-select-option value="1">Square Meters</b-form-select-option>
                                <b-form-select-option value="2">Packs</b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- Square Meters -->
                        <b-row v-if="order.order_items[ind].measurement_types_id === '1'">
                          <!-- Quantity Calculations -->
                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.width')"
                              :state="validationState(errors.errors,'order_items.' + ind + '.width') " class="mt-4"
                              prepend="Width">
                              <b-form-input v-model="item.width" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.width')"
                                            @input="calculateSquareMeters(ind)"></b-form-input>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".width") }}
                            </small>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.length')"
                              :state="validationState(errors.errors,'order_items.' + ind + '.length')" class="mt-4"
                              prepend="Length">
                              <b-form-input v-model="item.length" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.length')"
                                            @input="calculateSquareMeters(ind)"></b-form-input>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".length") }}
                            </small>
                          </b-col>
                          <!-- End Quantity -->

                          <!-- Row 2 -->
                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                              class="mt-4" prepend="Price £">
                              <b-form-input v-model="item.sale_price" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                            placeholder="Price Inc VAT"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price") }}
                            </small>
                          </b-col>
                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback( errors.errors,'order_items.' + ind + '.discount')"
                              class="mt-4" prepend="Discount £">
                              <b-form-input v-model="item.discount" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState( errors.errors,'order_items.' + ind + '.discount')"/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group :invalid-feedback="validationInvalidFeedback( errors.errors,'color')"
                                           class="mt-4" prepend="Colour">
                              <b-form-input v-model="item.color" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.color')"
                                            placeholder="Product Colour"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".color") }}
                            </small>
                          </b-col>

                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group :invalid-feedback="validationInvalidFeedback( errors.errors,'design')"
                                           class="mt-4" prepend="Design">
                              <b-form-input v-model="item.design" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.design')"
                                            placeholder="Product Design"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".design") }}
                            </small>
                          </b-col>

                          <!-- Shipping/Dispatch -->
                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.ship_date')"
                              class="mt-4" prepend="Shipping Date">
                              <b-form-datepicker v-model="item.ship_date"
                                                 :date-format-options="{ year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                 :disabled="isSaving || itemAlreadySaved(ind)"
                                                 :state="validationState(errors.errors,'order_items.' + ind + '.ship_date')"
                                                 close-buttonreset-buttonsize="sm" @input="updateOrderItemTotal(ind)"/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.dispatch_date')"
                              class="mt-4" prepend="Dispatch Date">
                              <b-form-datepicker v-model="item.dispatch_date"
                                                 :date-format-options="{ year: 'numeric', month: 'numeric',day: 'numeric'}"
                                                 :disabled="isSaving || itemAlreadySaved(ind)"
                                                 :state="validationState(errors.errors,'order_items.' + ind + '.dispatch_date')"
                                                 close-buttonreset-buttonsize="sm" @input="updateOrderItemTotal(ind)"/>
                            </b-input-group>
                          </b-col>
                          <!-- End Shipping/Dispatch -->
                          <b-col class="mt-4" cols="12">
                            <b-card>
                              <div class="d-flex flex-row">
                                <div class="flex-fill">
                                  <h4 class="mb-0">Fitting Details</h4>
                                </div>
                                <div>
                                  <b-form-checkbox v-model="item.fitting_required"
                                                   :disabled="isSaving || itemAlreadySaved(ind)"
                                                   :state="validationState( errors.errors,'order_items.' +ind +'.fitting_required')"
                                                   :unchecked-value="false" :value="true">Fitting Required
                                  </b-form-checkbox>
                                </div>
                              </div>
                              <hr v-if="item.fitting_required === true"/>
                              <b-row v-if="item.fitting_required === true">
                                <b-col cols="12">
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                    class="mt-4" prepend="Fitting Date">
                                    <b-form-datepicker v-model="item.fitting_date"
                                                       :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                       :disabled="isSaving || itemAlreadySaved(ind)"
                                                       :state="validationState(errors.errors,'order_items.' + ind + '.fitting_date')"
                                                       close-button
                                                       close-buttonreset-buttonsize="sm"
                                                       reset-button/>
                                  </b-input-group>
                                  <small
                                    class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                    }}</small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + 'fitting_time') "
                                    class="mt-4" prepend="Fitting Time">
                                    <b-form-input v-model="item.fitting_time"
                                                  :disabled="isSaving || itemAlreadySaved(ind) "
                                                  :state=" validationState( errors.errors,'order_items.' + ind + '.fitting_time')"
                                                  placeholder="HH:MM"/>
                                  </b-input-group>
                                  <small
                                    class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                    }}</small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_charge')"
                                    class="mt-4" prepend="Fitting Charge">
                                    <b-form-select v-model="item.fitting_charge"
                                                   :disabled="isSaving || itemAlreadySaved(ind)"
                                                   :state=" validationState(errors.errors,'order_items.' +ind +'.fitting_charge')">
                                      <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                      <b-form-select-option value="Invoice Fitting Charge">Invoice
                                      </b-form-select-option>
                                      <b-form-select-option value="Direct Fitting Charge">Direct</b-form-select-option>
                                    </b-form-select>
                                  </b-input-group>
                                  <small
                                    class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                    }}</small>
                                  <b-input-group v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                 class="mt-4" prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_inc_vat"
                                                  :disabled="isSaving || itemAlreadySaved(ind)"
                                                  :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                  placeholder="Fitting Cost Inc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                         class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                    }}</small>
                                  <b-input-group v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                 class="mt-4" prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_exc_vat"
                                                  :disabled=" isSaving || itemAlreadySaved(ind)"
                                                  :state="validationState( errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                  placeholder="Fitting Cost Exc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if="item.fitting_charge ==='Direct Fitting Charge'" class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitter_color')"
                                    class="mt-4" prepend="Fitter">
                                    <b-form-select v-model="item.fitter_color"
                                                   :disabled="isSaving || itemAlreadySaved(ind)"
                                                   :state="validationState(errors.errors,'order_items.' + ind + '.fitter_color')">
                                      <b-form-select-option :value="null" default>Please select a fitter
                                      </b-form-select-option>
                                      <b-form-select-option value="#4cd137">Darren</b-form-select-option>
                                      <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
                                      <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
                                      <b-form-select-option value="#d63031">Mark</b-form-select-option>
                                      <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
                                      <b-form-select-option value="#e67e22">Craig</b-form-select-option>
                                      <b-form-select-option value="#e84393">Danny</b-form-select-option>
                                      <b-form-select-option value="##b2bec3">Dave</b-form-select-option>
                                      <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
                                      <b-form-select-option value="#636e72">Carl</b-form-select-option>
                                    </b-form-select>
                                  </b-input-group>
                                  <small
                                    class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitter_color")
                                    }}</small>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>
                          <!-- End Costs -->

                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                              class="mt-4" label="Supplier">
                              <supplier-search
                                v-model="item.supplier"
                                :disabled="isSaving || itemAlreadySaved(ind)"
                                :error-feed="errors.errors"
                                :error-key="'supplier_id'"
                                type="supplier"
                                @input="supplierUpdated(ind)"/>
                            </b-form-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                            </small>
                          </b-col>

                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                              class="mt-4 w-100">
                              <b-form-checkbox v-model="item.staircase" :disabled="isSaving || itemAlreadySaved(ind)"
                                               :state=" validationState( errors.errors, 'order_items.' + ind + '.staircase') "
                                               :value="true">Staircase Involved
                              </b-form-checkbox>
                            </b-input-group>
                          </b-col>
                        </b-row>

                        <!-- Packs -->
                        <b-row v-else-if="order.order_items[ind].measurement_types_id === '2'">
                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.quantity')"
                              class="mt-4" prepend="Quantity">
                              <b-form-input v-model="item.quantity" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.quantity')"
                                            @input="updateOrderItemTotal(ind)"></b-form-input>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".quantity") }}
                            </small>
                          </b-col>

                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price' )"
                              class="mt-4" prepend="Price £">
                              <b-form-input v-model="item.sale_price" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state=" validationState( errors.errors,'order_items.' + ind + '.sale_price' )"
                                            placeholder="Price Inc VAT"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price") }}
                            </small>
                          </b-col>

                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                              class="mt-4" prepend="Discount £">
                              <b-form-input v-model="item.discount" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state=" validationState(  errors.errors,'order_items.' + ind + '.discount' ) "/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" lg="6">
                            <b-input-group :invalid-feedback="validationInvalidFeedback(errors.errors,'color')"
                                           class="mt-4" prepend="Colour">
                              <b-form-input v-model="item.color" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state=" validationState( errors.errors, 'order_items.' + ind + '.color' ) "
                                            placeholder="Product Color"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".color") }}
                            </small>
                          </b-col>

                          <b-col cols="12" lg="6">
                            <b-input-group :invalid-feedback="validationInvalidFeedback(errors.errors,'design')"
                                           class="mt-4" prepend="Design">
                              <b-form-input v-model="item.design" :disabled="isSaving || itemAlreadySaved(ind)"
                                            :state=" validationState(errors.errors,'order_items.' + ind + '.design')"
                                            placeholder="Product Design"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".design") }}
                            </small>
                          </b-col>

                          <b-col cols="12" lg="6">
                            <b-input-group
                              :invalid-feedback=" validationInvalidFeedback( errors.errors,'order_items.' + ind + '.ship_date')"
                              class="mt-4" prepend="Shipping Date">
                              <b-form-datepicker v-model="item.ship_date"
                                                 :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"
                                                 :disabled="isSaving || itemAlreadySaved(ind)"
                                                 :state=" validationState( errors.errors, 'order_items.' + ind + '.ship_date' ) "
                                                 close-button reset-button size="sm"
                                                 @input="updateOrderItemTotal(ind)"/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" lg="6">
                            <b-input-group
                              :invalid-feedback=" validationInvalidFeedback(errors.errors,'order_items.' + ind + '.dispatch_date' ) "
                              class="mt-4" prepend="Dispatch Date">
                              <b-form-datepicker v-model="item.dispatch_date"
                                                 :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                 :disabled="isSaving || itemAlreadySaved(ind)"
                                                 :state=" validationState( errors.errors, 'order_items.' + ind + '.dispatch_date' ) "
                                                 close-button reset-button size="sm"
                                                 @input="updateOrderItemTotal(ind)"/>
                            </b-input-group>
                          </b-col>

                          <b-col class="mt-4" cols="12">
                            <b-card>
                              <div class="d-flex flex-row">
                                <div class="flex-fill">
                                  <h4 class="mb-0">Fitting Details</h4>
                                </div>
                                <div>
                                  <b-form-checkbox v-model="item.fitting_required"
                                                   :disabled="isSaving || itemAlreadySaved(ind)"
                                                   :state="validationState(errors.errors,'order_items.' +ind +'.fitting_required')"
                                                   :unchecked-value="false"
                                                   :value="true"
                                  >Fitting Required
                                  </b-form-checkbox
                                  >
                                </div>
                              </div>
                              <hr v-if="item.fitting_required === true"/>
                              <b-row v-if="item.fitting_required === true">
                                <b-col cols="12">
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                    class="mt-4" prepend="Fitting Date">
                                    <b-form-datepicker v-model="item.fitting_date"
                                                       :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                       :disabled="isSaving || itemAlreadySaved(ind)"
                                                       :state="validationState(errors.errors,'order_items.' + ind + '.fitting_date')"
                                                       close-button
                                                       close-buttonreset-buttonsize="sm"
                                                       reset-button/>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_time')"
                                    class="mt-4" prepend="Fitting Time">
                                    <b-form-input v-model="item.fitting_time"
                                                  :disabled="isSaving || itemAlreadySaved(ind)"
                                                  :state="validationState(errors.errors,'order_items.' + ind + '.fitting_time')"
                                                  placeholder="HH:MM"/>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_charge')"
                                    class="mt-4" prepend="Fitting Charge">
                                    <b-form-select v-model="item.fitting_charge"
                                                   :disabled="isSaving || itemAlreadySaved(ind)"
                                                   :state="validationState( errors.errors,'order_items.' + ind +'.fitting_charge')">
                                      <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                      <b-form-select-option value="Invoice Fitting Charge">Invoice
                                      </b-form-select-option>
                                      <b-form-select-option value="Direct Fitting Charge">Direct</b-form-select-option>
                                    </b-form-select>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                    }}
                                  </small>
                                  <b-input-group v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind +'.fitting_cost_inc_vat')"
                                                 class="mt-4" prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_inc_vat"
                                                  :disabled="isSaving || itemAlreadySaved(ind) "
                                                  :state=" validationState( errors.errors,'order_items.' +  ind +  '.fitting_cost_inc_vat' ) "
                                                  placeholder="Fitting Cost Inc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if=" item.fitting_charge ===  'Invoice Fitting Charge' " class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                    }}</small>
                                  <b-input-group v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                                 :invalid-feedback="  validationInvalidFeedback(  errors.errors, 'order_items.' + ind + '.fitting_cost_exc_vat' ) "
                                                 class="mt-4" prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_exc_vat"
                                                  :disabled=" isSaving || itemAlreadySaved(ind) "
                                                  :state=" validationState( errors.errors,'order_items.' + ind + '.fitting_cost_exc_vat' ) "
                                                  placeholder="Fitting Cost Exc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if=" item.fitting_charge === 'Direct Fitting Charge' "
                                         class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                    }}</small>
                                  <b-input-group
                                    :invalid-feedback=" validationInvalidFeedback(  errors.errors, 'order_items.' + ind + '.fitter_color' )"
                                    class="mt-4" prepend="Fitter">
                                    <b-form-select v-model="item.fitter_color"
                                                   :disabled="  isSaving || itemAlreadySaved(ind)"
                                                   :state=" validationState( errors.errors,'order_items.' + ind + '.fitter_color')">
                                      <b-form-select-option :value="null" default>Please select a fitter
                                      </b-form-select-option>
                                      <b-form-select-option value="#4cd137">Darren</b-form-select-option>
                                      <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
                                      <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
                                      <b-form-select-option value="#d63031">Mark</b-form-select-option>
                                      <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
                                      <b-form-select-option value="#e67e22">Craig</b-form-select-option>
                                      <b-form-select-option value="#e84393">Danny</b-form-select-option>
                                      <b-form-select-option value="##b2bec3">Dave</b-form-select-option>
                                      <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
                                      <b-form-select-option value="#636e72">Carl</b-form-select-option>
                                    </b-form-select>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitter_color")
                                    }}
                                  </small>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>

                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                              class="mt-4" label="Supplier">
                              <supplier-search
                                v-model="item.supplier"
                                :disabled="isSaving || itemAlreadySaved(ind)"
                                :error-feed="errors.errors"
                                :error-key="'supplier_id'"
                                type="supplier"
                                @input="supplierUpdated(ind)"/>
                            </b-form-group>
                            <small
                              class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                            </small>
                          </b-col>

                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback=" validationInvalidFeedback( errors.errors,'order_items.' + ind + '.staircase') "
                              class="mt-4 w-100">
                              <b-form-checkbox v-model="item.staircase" :disabled="isSaving || itemAlreadySaved(ind)"
                                               :state="validationState( errors.errors,'order_items.' + ind + '.staircase')"
                                               :value="true">Staircase Involved
                              </b-form-checkbox>
                            </b-input-group>
                          </b-col>
                        </b-row>
                        <!-- End Packs -->
                      </b-card>

                      <!-- Split Shipping Modal -->
                      <b-modal id="split_shipping_modal" hide-footer>
                        <b-row
                          v-if="splitShippingItemData.splittingIndex !== null && order.order_items[splitShippingItemData.splittingIndex].fitting_required === true">
                          <b-col cols="12" md="6">
                            <b-input-group class="mb-4" prepend="Shipping Date">
                              <b-form-datepicker v-model="splitShippingItemData.shippingDate"
                                                 :date-format-options="{  year: 'numeric',  month: 'numeric',  day: 'numeric'}"
                                                 close-button reset-button/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group class="mb-4" prepend="Fitting Date">
                              <b-form-datepicker v-model="splitShippingItemData.fitting_date"
                                                 :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"
                                                 close-button reset-button/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12">
                            <div class="flex flex-row ml-auto space-x-2 w-full lg:w-96">
                              <b-button block variant="light" @click="cancelSplitShipping()">Close</b-button>
                              <b-button block class="align-self-end mb-3" variant="primary" @click="splitShipping()">
                                <b-spinner v-if="isSaving" small></b-spinner>
                                <span v-if="!isSaving">Save</span></b-button>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row v-else>
                          <b-col cols="12">
                            <b-input-group class="mb-4" prepend="Shipping Date">
                              <b-form-datepicker v-model="splitShippingItemData.shippingDate"
                                                 :date-format-options="{  year: 'numeric',  month: 'numeric',  day: 'numeric'}"
                                                 close-button reset-button/>
                            </b-input-group>
                          </b-col>
                          <b-col cols="12">
                            <div class="flex flex-row ml-auto space-x-2 w-full lg:w-96">
                              <b-button block variant="light" @click="cancelSplitShipping()">Close</b-button>
                              <b-button block class="align-self-end mb-3" variant="primary" @click="splitShipping()">
                                <b-spinner v-if="isSaving" small></b-spinner>
                                <span v-if="!isSaving">Save</span></b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </b-modal>

                      <!-- Set Fitter/Fitting Modal -->
                      <b-modal id="mass_assign_fitting" hide-footertitle="Mass Assign Fitting">
                        <b-row>
                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group class="mb-4" prepend="Fitting Date">
                              <b-form-datepicker v-model="massAssignItemFittingData.fitting_date"
                                                 :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                 close-buttonreset-buttonsize="sm"/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" lg="6" md="12" xl="6">
                            <b-input-group class="mb-4" prepend="Fitting Time">
                              <b-form-input v-model="massAssignItemFittingData.fitting_time" placeholder="HH:MM"/>
                            </b-input-group>
                          </b-col>

                          <b-col ccols="12" lg="6" md="12" xl="6">
                            <b-input-group class="mb-4" prepend="Fitter">
                              <b-form-select v-model="massAssignItemFittingData.fitter_color">
                                <b-form-select-option :value="null" default>No Fitter Selected</b-form-select-option>
                                <b-form-select-option value="#4cd137">Darren</b-form-select-option>
                                <b-form-select-option value="#00a8ff">Scott</b-form-select-option>
                                <b-form-select-option value="#8c7ae6">Jonathan</b-form-select-option>
                                <b-form-select-option value="#d63031">Mark</b-form-select-option>
                                <b-form-select-option value="#fbc531">Bobby</b-form-select-option>
                                <b-form-select-option value="#e67e22">Craig</b-form-select-option>
                                <b-form-select-option value="#e84393">Danny</b-form-select-option>
                                <b-form-select-option value="##b2bec3">Dave</b-form-select-option>
                                <b-form-select-option value="#e1b12c">Mick</b-form-select-option>
                                <b-form-select-option value="#636e72">Carl</b-form-select-option>
                              </b-form-select>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12">
                            <div class="flex flex-row ml-auto space-x-2 w-full lg:w-96">
                              <b-button blockvariant="light" @click="cancelApplyFitterAndFittingDate()">Close</b-button>
                              <b-button block class="align-self-end mb-3" variant="primary"
                                        @click="applyFitterAndFittingDate()">
                                <b-spinner v-if="isSaving" small></b-spinner>
                                <span v-if="!isSaving">Save</span></b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </b-modal>
                    </div>
                  </b-col>

                  <b-col class="mt-3" cols="12">
                    <b-button block variant="primary" @click.prevent="addItem">
                      <fa-icon icon="plus"/>
                      Add Item
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <small class="text-danger">
                {{ validationInvalidFeedback(errors.errors, "order_items") }}
              </small>
            </b-col>
          </b-row>

          <!-- Terms And Conditions-->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-row">
                  <div class="flex-fill">
                    <h4 class="mb-0">Terms and Conditions</h4>
                  </div>
                </div>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-column p-6">
                      <small class="mt-3 mb-3">
                        Fitting is carried out by local self employed specialists and we can arrange for one of them to
                        deliver and fit your flooring. This is a separate agreement between yourself and the fitting
                        team. If you’d like the fitters details to correspond with the fitter directly we can provide
                        these
                        for you. The balance of the fitting cost is payable directly to the fitter at each completed
                        stage of the
                        fitting process. Full details of the terms and conditions are set out and attached with your
                        invoice.
                      </small>
                      <small>Please tick the box if you have read and agree to our privacy policy and the attached
                        <a href="/termsandconditions/North-East-Carpets-Terms-And-Conditions.pdf"
                           style="text-decoration: underline !important" target="_blank">terms and conditions.</a>
                      </small>
                      <b-form-checkbox v-model="order.customer_declaration" class="mt-3" required>I accept the terms and
                        conditions
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <small class="text-danger">
                {{ validationInvalidFeedback(errors.errors, "customer_declaration") }}
              </small>
            </b-col>
          </b-row>

          <!-- Order Notes -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                         @click.prevent="toggleCollapse('order_notes')">
                    <b-icon :icon=" collapsed['order_notes'] ? 'chevron -right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4"><p class="h4 font-weight-bold mb-0">Order Notes</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['order_notes']" class="mt-4">
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')"
                                  label="Create a note for this order">
                      <b-form-textarea v-model="newNote" :state="validationState(errors.errors, 'newNote')"
                                       placeholder="Insert note here..." size="sm"></b-form-textarea>
                    </b-form-group>
                  </b-col>

                  <!-- Add New Note Field -->
                  <b-col v-if="order.order_notes.length" cols="12">
                    <hr/>
                    <b-row v-for="(note, noteInd) in order.order_notes" :key="noteInd">
                      <b-col
                        :invalid-feedback=" validationInvalidFeedback( errors.errors,'order_notes.' + noteInd + '.note')"
                        cols="12">
                        <b-card class="p-4 mt-3" no-body>
                          <p class="note">{{ note.note }}</p>
                          <hr/>
                          <b-row>
                            <b-col class="d-flex flex-row justify-content-start align-items-center" cols="9">
                              <p class="note-created"> Left by: {{ note.user.name }} </p>
                            </b-col>
                            <b-col class="d-flex flex-row justify-content-end align-items-center" cols="3">
                              <p class="note-created text-muted">
                                <FriendlyDate :date="note.created_at"></FriendlyDate>
                              </p>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col md="4" offset-md="8">
              <b-button block class="submit-button" type="button" variant="secondary" @click="checkForLotsPrompt">
                <b-spinner v-if="isSaving" small></b-spinner>
                <span v-if="!isSaving">Save</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-modal id="discountModal" title="Set All Discount" @ok="setDiscountsUsingPercentages">
        <b-row>
          <b-col cols="12">
            <b-input-group class="mb-4" prepend="Discount Percentage">
              <b-form-input v-model="discountPercentageInput" type="text"/>
            </b-input-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal id="checkLotsModal" :busy="true" hide-footer title="Final Checks">
        <b-row>
          <b-col cols="12">
            <p>Please check that you have checked the stair case box if required</p>
            <p>Please check available lots before submitting this order if applicable to avoid creating extra supply
              orders.</p>
            <b-button class="mr-4" type="submit" variant="primary" @click="submit">
              <b-spinner v-if="isSaving" small></b-spinner>
              <span v-if="!isSaving">Save</span>
            </b-button>
            <b-button type="submit" variant="secondary" @click="$bvModal.hide('checkLotsModal')">Cancel</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-form>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import validation from "../../mixins/validation";
import momentMixin from "../../mixins/momentMixin";
import currentUser from "../../mixins/currentUser";

import UserSearch from "../../components/UserSearch";
import CustomerSearch from "../../components/CustomerSearch";
import ProductSearch from "../../components/ProductSearch";
import FriendlyDate from "../../components/FriendlyDate";
import LenderSearch from "../../components/LenderSearch";
import SupplierSearch from "../../components/SupplierSearch";
import SupplyOrderItemLotSearch from "../../components/SupplyOrderItemLotSearch";

export default {
  mixins: [validation, momentMixin, currentUser],
  components: {
    UserSearch,
    CustomerSearch,
    ProductSearch,
    FriendlyDate,
    LenderSearch,
    SupplierSearch,
    SupplyOrderItemLotSearch
  },

  data() {
    return {
      collapsed: {
        order_items: !this.$route.params.id,
        order_notes: !this.$route.params.id,
        order_uploads: true,
        initial_payment: !this.$route.params.id,
        finance: true
      },

      splitShippingItemData: {
        splittingIndex: null,
        shippingDate: null,
        fitting_date: null,
        discount: null,
      },

      massAssignItemFittingData: {
        fittingIndex: null,
        fitting_date: null,
        fitting_time: null,
        fitter_color: null
      },

      newNote: null,

      counter: 0,

      order: {
        user_id: null,
        user: {},
        customer_id: null,
        customer: {},
        measurement_types: {},
        order_date: new Date().toISOString().slice(0, 10),
        quote_source: null,
        payments_id: null,
        payments: [],
        order_uploads: [],
        documents: null,
        order_items: [],
        sub_total: null,
        vat: null,
        total_price: null,
        lender_id: null,
        lender: {},
        finance_amount: null,
        subsidy: null,
        subsidy_percentage: null,
        finance_interest: null,
        order_notes: [],
        customer_declaration: null,
        supply_order_item_lot: {
          id: null
        }
      },
      initialPayment: {
        customer_id: null,
        customer: {},
        location: null,
        amount: null,
        method: null,
        date: null,
        lender_id: null,
        lender: {},
        subsidy: null,
        interest: null,
        other: null
      },
      discountPercentageInput: null,
    };
  },

  mounted() {
    if (!this.$route.params.id) {
      this.order.user = this.currentUser;
      this.order.user_id = this.currentUser.id;

      if (this.$route.query.customer_id) {
        this.order.customer_id = this.$route.query.customer_id;
      }
    } else {
      this.fetch(this.$route.params.id);
    }
  },

  computed: {
    ...mapGetters("orders", ["errors", "isLoading", "isSaving", "single"]),

    computedVatTotal() {
      return (
        Number(this.computedOrderTotal) - Number(this.computedSubTotal)
      ).toFixed(2);
    },

    computedSubTotal() {
      return (Number(this.computedOrderTotal) / 1.2).toFixed(2);
    },

    computedOrderTotal() {
      let orderTotal = 0;
      this.order.order_items.forEach(item => {
        if (!Object.keys(item).includes("deleted_at") || !item.deleted_at) {
          if (
            !isNaN(item.sale_price) &&
            !isNaN(item.quantity) &&
            !isNaN(item.fitting_cost_inc_vat)
          ) {
            let itemTotal =
              Number(item.sale_price) * Number(item.quantity) +
              Number(item.fitting_cost_inc_vat) -
              Number(item.discount);
            orderTotal += Number(itemTotal);
          }
        }
      });
      return Number(orderTotal).toFixed(2);
    },

    computedFittingTotal() {
      let fittingTotal = 0;
      this.order.order_items.forEach(item => {
        if (!Object.keys(item).includes("deleted_at") || !item.deleted_at) {
          if (!isNaN(item.fitting_cost_inc_vat)) {
            fittingTotal += Number(item.fitting_cost_inc_vat);
          }
          if (!isNaN(item.fitting_cost_exc_vat)) {
            fittingTotal += Number(item.fitting_cost_exc_vat);
          }
        }
      });
      return Number(fittingTotal).toFixed(2);
    },

    computedPaymentTotal() {
      let paymentTotal = 0;
      this.order.payments.forEach(payment => {
        if (!isNaN(payment.amount)) {
          paymentTotal += Number(payment.amount);
        }
      });
      return paymentTotal;
    },

    computedSubsidy() {
      if (this.order.finance_amount && this.order.subsidy_percentage) {
        return;
      }
      return 0;
    },

    // Remaining Total Calculation
    result: function () {
      let calc = this.computedOrderTotal - this.computedPaymentTotal;
      let calcTwoDP = parseFloat(calc).toFixed(2);
      return calcTwoDP - Number(this.order.subsidy);
    }
  },
  methods: {
    ...mapActions("orders", ["fetch", "save"]),

    openDiscountModal() {
      this.$bvModal.show('discountModal');
    },

    setDiscountsUsingPercentages() {
      let discountPercentage = this.discountPercentageInput;

      if (!isNaN(discountPercentage)) {
        if (discountPercentage >= 1 && discountPercentage <= 100) {
          this.order.order_items.forEach(orderItem => {
            orderItem.discount = ((discountPercentage / 100) * orderItem.total_price).toFixed(2);
          });
        }
      }
    },

    getCounterId() {
      return `-new-${++this.counter}`;
    },

    // Returns true or false and basically tells the system whether the item has been saved previously
    itemAlreadySaved(ind) {
      return !isNaN(this.order.order_items[ind].id);
    },

    calculateSubsidy() {
      let subsidy = 0;
      if (this.order.finance_amount && this.order.subsidy_percentage) {
        subsidy = (
          (this.order.subsidy_percentage / 100) *
          this.order.finance_amount
        ).toFixed(2);
      }
      this.order.subsidy = subsidy;
    },

    calculateSquareMeters(ind) {
      let totalQuantity =
        Math.round(
          (Number(this.order.order_items[ind].width) *
            Number(this.order.order_items[ind].length) +
            Number.EPSILON) * 100) / 100;
      this.order.order_items[ind].quantity = totalQuantity;
      this.updateOrderItemTotal(ind);
      return totalQuantity;
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },

    lotShouldBeDisabled(ind) {
      let orderItem = this.order.order_items[ind];
      return !Boolean(orderItem["length"] && orderItem.product_variation_id);
    },

    deselectItem(ind) {
      this.order.order_items[ind].product_id = null;
      this.order.order_items[ind].supplier_id = null;
      this.order.order_items[ind].supplier = {id: null};
      this.order.order_items[ind].product_variation_id = null;
      this.order.order_items[ind].product_variation_name = null;
      this.order.order_items[ind].name = null;
      this.order.order_items[ind].product = {id: null};
    },

    checkForLotsPrompt() {
      let showModal = false
      this.order.order_items.forEach(item => {
        if (item.measurement_types_id === 1) {
          showModal = true
        }
      });

      if (showModal === false) {
        this.submit();
      } else {
        this.showLotsModal();
      }
    },

    showLotsModal() {
      this.$bvModal.show('checkLotsModal');
    },

    submit() {
      this.calculateSubsidy();

      let formData = new FormData();
      formData.append("id", this.$route.params.id);
      formData.append("user_id", this.order.user_id ?? "");
      formData.append("customer_id", this.order.customer_id ?? "");
      formData.append("order_date", this.order.order_date ?? "");
      formData.append("quote", 0);
      formData.append("quote_source", this.order.quote_source ?? "");
      formData.append("sale_price", this.order.sale_price ?? "");
      formData.append("sub_total", this.computedSubTotal ?? "");
      formData.append("vat", this.computedVatTotal ?? "");
      formData.append("total_price", this.computedOrderTotal ?? "");
      formData.append("quantity", this.order.order_items.quantity ?? "");
      formData.append("newNote", this.newNote ?? "");
      formData.append("documents", this.order.documents ?? "");
      formData.append("customer_declaration", this.order.customer_declaration ? 1 : "");

      if (this.order.lender_id || this.order.finance_amount || this.order.subsidy || this.order.finance_interest) {
        formData.append("lender_id", this.order.lender_id ?? "");
        formData.append("finance_amount", this.order.finance_amount ?? "");
        formData.append("subsidy", this.order.subsidy ?? "");
        formData.append("subsidy_percentage", this.order.subsidy_percentage ?? "");
        formData.append("finance_interest", this.order.finance_interest ?? "");
      }

      this.order.order_items.forEach((item, itemIndex) => {
        formData.append(`order_items[${itemIndex}][id]`, item.id ?? "");
        formData.append(`order_items[${itemIndex}][product_id]`, item.product_id ?? "");
        formData.append(`order_items[${itemIndex}][product_variation_id]`, item.product_variation_id ?? "");
        formData.append(`order_items[${itemIndex}][product_variation_name]`, item.product_variation_name ?? "");
        formData.append(`order_items[${itemIndex}][product]`, item.product ?? "");
        formData.append(`order_items[${itemIndex}][name]`, item.name ?? "");
        formData.append(`order_items[${itemIndex}][quantity]`, item.quantity ?? "");
        formData.append(`order_items[${itemIndex}][width]`, item.width ?? "");
        formData.append(`order_items[${itemIndex}][length]`, item.length ?? "");
        formData.append(`order_items[${itemIndex}][measurement_types_id]`, item.measurement_types_id ?? "");

        formData.append(`order_items[${itemIndex}][cost_price]`, item.cost_price === "" || item.cost_price == null ? "" : !isNaN(item.cost_price) ? Number(item.cost_price).toFixed(2) : item.cost_price);
        formData.append(`order_items[${itemIndex}][sale_price]`, item.sale_price === "" || item.sale_price == null ? "" : !isNaN(item.sale_price) ? Number(item.sale_price).toFixed(2) : item.sale_price);
        formData.append(`order_items[${itemIndex}][sub_total]`, item.sub_total === "" || item.sub_total == null ? "" : !isNaN(item.sub_total) ? Number(item.sub_total).toFixed(2) : item.sub_total);
        formData.append(`order_items[${itemIndex}][vat]`, item.vat === "" || item.vat == null ? "" : !isNaN(item.vat) ? Number(item.vat).toFixed(2) : item.vat);
        formData.append(`order_items[${itemIndex}][total_price]`, item.total_price === "" || item.total_price == null ? "" : !isNaN(item.total_price) ? Number(item.total_price).toFixed(2) : item.total_price);
        formData.append(`order_items[${itemIndex}][fitting_cost_inc_vat]`, item.fitting_cost_inc_vat === "" || item.fitting_cost_inc_vat == null ? "" : !isNaN(item.fitting_cost_inc_vat) ? Number(item.fitting_cost_inc_vat).toFixed(2) : item.fitting_cost_inc_vat);
        formData.append(`order_items[${itemIndex}][fitting_cost_exc_vat]`, item.fitting_cost_exc_vat === "" || item.fitting_cost_exc_vat == null ? "" : !isNaN(item.fitting_cost_exc_vat) ? Number(item.fitting_cost_exc_vat).toFixed(2) : item.fitting_cost_exc_vat);
        formData.append(`order_items[${itemIndex}][supply_order_item_lot_id]`, item.supply_order_item_lot ? item.supply_order_item_lot.id : "");

        formData.append(`order_items[${itemIndex}][discount]`, item.discount ?? "");
        formData.append(`order_items[${itemIndex}][ship_date]`, item.ship_date ?? "");
        formData.append(`order_items[${itemIndex}][dispatch_date]`, item.dispatch_date ?? "");
        formData.append(`order_items[${itemIndex}][staircase]`, item.staircase ?? "");
        formData.append(`order_items[${itemIndex}][fitting_required]`, item.fitting_required);
        formData.append(`order_items[${itemIndex}][fitting_date]`, item.fitting_date ?? "");
        formData.append(`order_items[${itemIndex}][fitting_time]`, item.fitting_time ?? "");
        formData.append(`order_items[${itemIndex}][fitting_charge]`, item.fitting_charge ?? "");
        formData.append(`order_items[${itemIndex}][supplier_id]`, item.supplier_id ?? "");
        formData.append(`order_items[${itemIndex}][color]`, item.color ?? "");
        formData.append(`order_items[${itemIndex}][fitter_color]`, item.fitter_color ?? "#000000");
        formData.append(`order_items[${itemIndex}][design]`, item.design ?? "");
      });

      if (!this.$route.params.id && this.initialPayment.location && this.initialPayment.amount && this.initialPayment.date) {
        formData.append("initial_payment[customer_id]", this.order.customer_id);
        formData.append("initial_payment[location]", this.initialPayment.location);
        formData.append("initial_payment[amount]", this.initialPayment.amount);
        formData.append("initial_payment[method]", this.initialPayment.method);
        formData.append("initial_payment[date]", this.initialPayment.date);
        formData.append("initial_payment[other]", this.initialPayment.other);
      }
      this.save({id: this.$route.params.id, data: formData}).then(
        response => {
          if ([200, 201].includes(response.status))
            this.$router.push({
              name: "dash.orders.list",
              params: {
                notifications: [
                  {
                    variant: "success",
                    title: `Order #${response.data.id}`,
                    subtitle: `${response.data.customer.name}`,
                    text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} order.`,
                    timeout: 5000
                  }
                ]
              }
            });
        }
      ).catch(error => {
        window.scrollTo(0, 0);
      });
    },

    // Mark all items as shipped
    markAllShipped() {
      this.order.order_items.forEach(item => {
        if (item.ship_date == null) {
          item.ship_date = this.moment().format("YYYY-MM-DD");
          this.$router.push();
        }
      });
    },

    // Split Shipping
    showSplitShippingModal(ind) {
      this.splitShippingItemData.splittingIndex = ind;
      this.$root.$emit("bv::show::modal", "split_shipping_modal");
    },

    cancelSplitShipping() {
      this.splitShippingItemData.shippingDate = null;
      this.splitShippingItemData.fitting_date = null;
      this.splitShippingItemData.fitting_time = null;
      this.splitShippingItemData.splittingIndex = null;
      this.$root.$emit("bv::hide::modal", "split_shipping_modal");
    },

    splitShipping() {
      let item = this.order.order_items[
        this.splitShippingItemData.splittingIndex
        ];

      let newQuantity = item.quantity / 2;
      let newFittingCost = item.fitting_cost / 2;
      let newShippingDate = item.ship_date;
      let newFittingDate = item.fitting_date;
      let newFittingTime = item.fitting_time;
      let newDiscount = item.discount / 2;

      item.quantity = newQuantity;
      item.fitting_cost = newFittingCost;
      item.ship_date = newShippingDate;
      item.fitting_date = newFittingDate;
      item.fitting_time = newFittingTime;
      item.discount = newDiscount;

      let orderItem = JSON.parse(JSON.stringify(item));
      orderItem.id = null;
      orderItem.ship_date = this.splitShippingItemData.shippingDate;
      orderItem.fitting_date = this.splitShippingItemData.fitting_date;
      orderItem.fitting_time = this.splitShippingItemData.fitting_time;

      this.order.order_items.push(orderItem);
      this.splitShippingItemData.splittingIndex = null;
      this.$root.$emit("bv::hide::modal", "split_shipping_modal");
    },

    // Assign the same fitter and fitting date to all items
    showMassAssignFittingModal() {
      this.$root.$emit("bv::show::modal", "mass_assign_fitting");
    },

    cancelApplyFitterAndFittingDate() {
      this.$root.$emit("bv::hide::modal", "mass_assign_fitting");
    },

    applyFitterAndFittingDate() {
      this.order.order_items.forEach(item => {
        if (item.fitting_required) {
          item.fitting_date = this.massAssignItemFittingData.fitting_date;
          item.fitting_time = this.massAssignItemFittingData.fitting_time;
          item.fitter_color = this.massAssignItemFittingData.fitter_color;
        }
      });

      this.$root.$emit("bv::hide::modal", "mass_assign_fitting");
    },

    userUpdated() {
      this.order.user_id = this.order.user.id;
    },

    customerUpdated() {
      this.order.customer_id = this.order.customer.id;
    },

    supplierUpdated(ind) {
      this.order.order_items[ind].supplier_id = this.order.order_items[
        ind
        ].supplier.id;
    },

    lenderUpdated() {
      this.order.lender_id = this.order.lender.id;
      this.order.subsidy_percentage = this.order.lender.subsidy;
      this.order.finance_interest = this.order.lender.interest;
      this.calculateSubsidy();
    },

    itemUpdated(ind) {
      this.order.order_items[ind].product_id = this.order.order_items[ind].product.id;
      this.order.order_items[ind].name = this.order.order_items[ind].product.name;
      this.order.order_items[ind].sale_price = this.order.order_items[ind].product.sale_price;
      this.order.order_items[ind].cost_price = this.order.order_items[ind].product.cost_price;
      this.order.order_items[ind].sub_total = this.order.order_items[ind].sale_price * this.order.order_items[ind].quantity;
      this.order.order_items[ind].supplier_id = this.order.order_items[ind].product.supplier.id;
      this.order.order_items[ind].supplier = {id: this.order.order_items[ind].product.supplier_id};
      this.order.order_items[ind].measurement_types_id = this.order.order_items[ind].product.measurement_types_id;
      this.order.order_items[ind].vat = this.order.order_items[ind].total_price - this.order.order_items[ind].sale_price;
    },

    productVariationUpdated(ind) {
      this.order.order_items[ind].supply_order_item_lot = {};

      let productVariation = this.order.order_items[ind].product.variations.find(variation => {
        return this.order.order_items[ind].product_variation_id === variation.id;
      });

      if (productVariation) {
        this.order.order_items[ind].product_variation_name = productVariation.name;
        this.order.order_items[ind].width = productVariation.width;
      }

      this.updateOrderItemTotal(ind);
    },

    addItem() {
      this.order.order_items.push({
        id: this.getCounterId(),
        product_id: null,
        product: {id: null},
        product_variation_id: null,
        name: null,
        product_variation_name: null,
        quantity: 0,
        width: 0,
        length: 0,
        measurement_types_id: null,
        cost_price: null,
        sale_price: null,
        sub_total: null,
        vat: null,
        total_price: null,
        discount: 0,
        ship_date: null,
        dispatch_date: null,
        staircase: 0,
        fitting_required: 0,
        fitting_date: null,
        fitting_time: null,
        fitting_charge: null,
        fitter_color: null,
        fitting_cost_inc_vat: null,
        fitting_cost_exc_vat: null,
        supplier: {id: null},
        supply_order_item_lot: {id: null},
        color: null,
        design: null
      });
    },

    rmItem(ind) {
      this.order.order_items.splice(ind, 1);
    },

    rmLot(ind) {
      this.order.order_items[ind].supply_order_item_lot = null;
    },

    updateOrderItemTotal(ind) {
      this.order.order_items[ind].total_price = 0;
      if (
        Number(this.order.order_items[ind].sale_price) &&
        Number(this.order.order_items[ind].quantity)
      ) {
        let totalPrice =
          Number(this.order.order_items[ind].sale_price) *
          Number(this.order.order_items[ind].quantity);
        if (this.order.order_items[ind].fitting_required) {
          switch (this.order.order_items[ind].fitting_charge) {
            case "Invoice Fitting Charge":
              if (this.order.order_items[ind].fitting_cost_inc_vat > 0) {
                totalPrice += Number(this.order.order_items[ind].fitting_cost_inc_vat);
              }
              break;
          }
        }
        if (Number(this.order.order_items[ind].discount)) {
          totalPrice -= this.order.order_items[ind].discount;
        }
        this.order.order_items[ind].total_price = totalPrice;
      }
    }
  },
  watch: {
    single() {
      this.order = this.single;
      this.order.customer_declaration = Boolean(
        this.order.customer_declaration
      );
      this.customer = this.single.customer;
    },
    currentUser() {
      this.order.user = this.currentUser;
      this.order.user_id = this.currentUser.id;
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  }
};
</script>

<style lang="scss">
.grey-bg .search .card {
  background-color: rgba(0, 0, 0, 0.05);
}

.note {
  font-size: 1rem;
}

.note-created {
  font-size: 0.9rem;
  margin: 0;
}
</style>
