<template>
  <b-container>
    <ResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
                  :search-query="$route.query.query" can-delete
                  create-to="dash.orders.create" resource="order" soft-deletes
                  title="Orders" @paginate="onPaginate" @search="onSearch"
                  @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div v-if="!item.completed" class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <router-link :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="text-dark">#{{ item.id }}
                {{ item.customer.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>

          <div v-else class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              <a class="text-dark" @click="openViewModal(item.id)">#{{ item.id }} {{ item.customer.name }}</a>
            </p>
            <p class="align-self-end mb-0 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>

          <div v-if="item.completed" class="mb-1">
            <small class="text-muted mb-2">Completed</small>
          </div>

          <div class="d-none d-md-flex flex-row">
            <b-button v-b-popover.hover.bottom="'View more information about this order.'"
                      :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button v-b-popover.hover.bottom="'Edit this order.'"
                      :to="{ name: 'dash.orders.update', params: { id: item.id } }" class="mr-1 mb-0" size="sm"
                      variant="secondary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>

    <!-- View Modal -->
    <b-modal id="view-modal" hide-footer title="Locked Order">
      <b-form-group label="Password:">
        <b-form-input v-model="auth.password" placeholder="Please enter the system password to access this order."
                      required
                      type="password"></b-form-input>
      </b-form-group>
      <div class="d-none d-md-flex flex-row">
        <b-button v-b-popover.hover.bottom="'View more information about this order.'" class="mr-1 mb-0" size="sm"
                  variant="primary"
                  @click="viewProtectedOrder(viewModalId)">
          <b-icon icon="cart"/>
          View
        </b-button>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal id="edit-modal" hide-footer title="Locked Order">
      <b-form-group label="System Password:">
        <b-form-input v-model="auth.password" placeholder="Please enter the system password to edit this order."
                      required
                      type="password"></b-form-input>
      </b-form-group>
      <div class="d-none d-md-flex flex-row">
        <b-button v-b-popover.hover.bottom="'Edit this order.'" class="mr-1 mb-0" size="sm" variant="primary"
                  @click="editProtectedOrder(editModalId)">
          <b-icon icon="cart"/>
          View
        </b-button>
      </div>
    </b-modal>
    <!-- End Modal -->
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchManyAwaitingLots(this.$route.query)

    this.$root.$on("bv::modal::hidden", () => {
      this.auth.password = null
    })
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("orders", ["data", "isDeleting", "isLoading", "pagination"])
  },
  data: () => ({
    auth: {
      password: null
    },

    viewModalId: null,
    editModalId: null,
    completedModalId: null
  }),
  methods: {
    ...mapActions("orders", ["deleteMany", "fetchManyAwaitingLots"]),
    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchManyAwaitingLots(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

    submit() {
      let data = {
        password: this.auth.password
      }

      this.save(data)
    },
    viewProtectedOrder(itemId) {
      window.axios
        .post(`orders/authenticate/${itemId}`, {
          password: this.auth.password
        })
        .then(response => {
          if (response.data) this.$router.push({
            name: "dash.orders.view",
            params: {id: itemId, pass: this.auth.password}
          })
          this.auth.password = null
        })
    },
    editProtectedOrder(itemId) {
      window.axios
        .post(`orders/authenticate/${itemId}`, {
          password: this.auth.password
        })
        .then(response => {
          if (response.data) this.$router.push({
            name: "dash.orders.update",
            params: {id: itemId, pass: this.auth.password}
          })
          this.auth.password = null
        })
    },

    openViewModal(itemId) {
      this.viewModalId = itemId
      this.$root.$emit("bv::show::modal", "view-modal")
    },

    openEditModal(itemId) {
      this.editModalId = itemId
      this.$root.$emit("bv::show::modal", "edit-modal")
    }
  }
}
</script>
